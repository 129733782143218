import moment from "moment";
import { Schedule } from "../ContextAgenda";
import ScheduleStatus from "../components/ScheduleStatus";
import { getInitialName } from "../../../../components/utils";

export interface ScheduleViewHeaderProps {
  schedule: Schedule;
}

export default function ScheduleViewHeader({
  schedule,
}: ScheduleViewHeaderProps) {
  const start = moment(schedule.start);
  const end = moment(schedule.end);

  return (
    <div className="flex justify-center">
      <div className="stats shadow w-full">
        <div className="stat">
          <div className="flex">
            <div className="avatar h-14 mr-2">
              <div className="bg-neutral mask mask-squircle text-neutral-content content-center text-center">
                {schedule.client.profileImageUrl ? (
                  <img
                    alt="Foto do Cliente"
                    src={schedule.client.profileImageUrl}
                  />
                ) : (
                  <span className="text-xl">
                    {getInitialName(schedule.client.name)}
                  </span>
                )}
              </div>
            </div>
            <div>
              <div>
                <div className="text-lg">{schedule.client.name}</div>
              </div>
              <button
                onClick={() => {
                  window.open(schedule.client.whatsappLink, "post");
                }}
                disabled={!schedule?.client?.whatsappLink}
                className="btn btn-circle btn-xs"
              >
                <i className="fa-brands fa-whatsapp"></i>
              </button>
            </div>
          </div>

          <div>
            <div className="text-xs my-2">
              {schedule.client.email}
              {!!schedule.client.phoneNumber &&
                ` | ${schedule.client.phoneNumber}`}
            </div>
          </div>
          <div className="stat-desc text-secondary">
            {(Number(schedule.client.amountSchedules) || 0) > 1
              ? `${schedule.client.amountSchedules} agendamentos realizados`
              : Number(schedule.client.amountSchedules) === 1
              ? "Primeiro agendamento realizado"
              : "Nenhum agendamento realizado"}
          </div>
        </div>
        <div className="stat">
          <div className="stat-title">Serviço</div>
          <div className="stat-value">{schedule.service.price}</div>
          <div className="stat-desc">{schedule.service.name}</div>
        </div>
        <div className="stat">
          <div className="stat-title">Horário</div>
          <div className="stat-value text-secondary">
            {start.format("HH:mm")} - {end.format("HH:mm")}
          </div>

          <ScheduleStatus status={schedule.status} id={schedule.id} />
        </div>
      </div>
    </div>
  );
}
