import { useEffect, useState } from "react";
import { HistoryService, ScheduleHistoryToView } from "../../../../history/Service";
const historyService = new HistoryService();

interface ScheduleHistoryProps {
  id: string;
}

export default function ScheduleHistory({ id }: ScheduleHistoryProps) {
  const [historys, setHistorys] = useState<ScheduleHistoryToView[]>([]);

  useEffect(() => {
    // historyService.getHistory(id).then(response => {
    //   setHistorys(response);
    // });
  }, [id]);

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr>
            <th>Histórico</th>
            <th>Data</th>
            <th>Usuário</th>
          </tr>
        </thead>
        <tbody>
          {historys.map((history) => (
            <tr className="hover" key={history.id}>
              <td>{history.message}</td>
              <td>{new Date(history.date).toLocaleString()}</td>
              <td>{history.user.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
