import axios from "axios";

export const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3001"
export const urlFile = `${baseURL}/files`

const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "authToken"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { api };
