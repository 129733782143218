import React, { useEffect, useState } from "react";
import moment from "moment";
import SelectAtendentes from "../../components/inputs/SelectAtendentes";
import { AusenciasService } from "./Service";
import { useAppContext } from "../../contexts/AppContext";
import { useAusenciasContext } from "./Context";

const service = new AusenciasService();

export default function AgendaSettingsAusenciasForm({
  showForm,
  setShowForm,
}: {
  showForm: any;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [loading, setLoading] = useState(false);
  const { errorSnackbar, successSnackbar } = useAppContext();
  const { loadAusencias } = useAusenciasContext();

  const [start, setStart] = useState<moment.Moment | null>(null);
  const [end, setEnd] = useState<moment.Moment | null>(null);
  const [atendentesIds, setAtendentesIds] = useState<string[]>([]);
  const [motivo, setMotivo] = useState<string>("");

  useEffect(() => {
    if (showForm?.id) {
      setLoading(true);
      service.findOne(showForm.id).then((response) => {
        const doc = response.data || {};

        if (doc.start) {
          setStart(moment(doc.start));
        } else {
          setStart(null);
        }

        if (doc.end) {
          setEnd(moment(doc.end));
        } else {
          setEnd(null);
        }

        if (doc?.atendentesIds?.length) {
          setAtendentesIds(doc.atendentesIds);
        }

        if (doc?.motivo) {
          setMotivo(doc.motivo);
        }

        setLoading(false);
      });
    }
  }, [showForm]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formState: any = {
      atendentesIds,
      motivo,
    };

    if (!!start) formState.start = start.toDate();
    if (!!end) formState.end = end.toDate();

    try {
      setLoading(true);

      if (showForm?.id) {
        await service.update(showForm?.id, formState);
      } else {
        await service.create(formState);
      }

      successSnackbar(
        `Ausência ${showForm?.id ? "editada" : "criada"} com sucesso!`
      );
      setShowForm(false);
      loadAusencias();
    } catch (error) {
      errorSnackbar(
        `Ocorreu um erro ao ${showForm?.id ? "editar" : "criar"} a ausência.`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value ? moment(e.target.value).local() : null;
    setStart(date);
  };

  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value ? moment(e.target.value).local() : null;
    setEnd(date);
  };

  return (
    <div className={showForm ? "block" : "hidden"}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Início</span>
          </label>
          <input
            type="datetime-local"
            name="start"
            value={start ? start.format("YYYY-MM-DDTHH:mm") : ""}
            onChange={handleStartChange}
            required
            className="input input-bordered"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Fim</span>
          </label>
          <input
            required
            type="datetime-local"
            name="end"
            value={end ? end.format("YYYY-MM-DDTHH:mm") : ""}
            onChange={handleEndChange}
            className="input input-bordered"
          />
        </div>
        <div className="form-control">
          <SelectAtendentes
            value={atendentesIds}
            setValue={setAtendentesIds}
            name="atendentes"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Motivo</span>
          </label>
          <textarea
            name="motivo"
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
            className="textarea textarea-bordered"
          />
        </div>

        <div className="divider"></div>

        <div className="flex space-x-2">
          <button disabled={loading} className="btn shadow-md" type="submit">
            {showForm?.id ? "Editar" : "Criar"}
          </button>
          <button
            onClick={() => setShowForm(false)}
            className="btn shadow-md btn-error"
            disabled={loading}
            type="button"
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}
