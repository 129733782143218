import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { AusenciasService } from "./Service";
import { useEstabsContext } from "../../contexts/EstabsContext";
import { AtendentesProvider } from "../atendentes/Context";

const serviceAusencias = new AusenciasService();

export interface Ausencias {
  id: string;
  start: Date;
  end: Date;
  atendentes: string[];
  motivo: string;
}

interface AusenciasContextProps {
  loading: boolean;
  ausencias: Ausencias[];
  loadAusencias: () => void;
}

const AusenciasContext = createContext<AusenciasContextProps | undefined>(
  undefined
);

const AusenciasProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { estabSession } = useEstabsContext();
  const [loading, setLoading] = useState(true);
  const [ausencias, setAusencias] = useState<Ausencias[]>([]);

  const loadAusencias = async () => {
    try {
      setLoading(true);

      await serviceAusencias.findAll().then((result) => {
        setAusencias(result?.data || []);
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (estabSession) {
      loadAusencias();
    }
  }, [estabSession]);

  return (
    <AusenciasContext.Provider
      value={{
        loading,
        ausencias,
        loadAusencias,
      }}
    >
      <AtendentesProvider>{children}</AtendentesProvider>
    </AusenciasContext.Provider>
  );
};

const useAusenciasContext = () => {
  const context = useContext(AusenciasContext);
  if (context === undefined) {
    throw new Error(
      "useAusenciasContext must be used within an AusenciasProvider"
    );
  }
  return context;
};

export { AusenciasProvider, useAusenciasContext };
