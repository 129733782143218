import { useParamsContext } from "../../contexts/ParamsContext";
import UsuariosSistemaForm from "./Form";
import UsuariosSistemaList from "./List";
import UsuariosSistemaDelete from "./Delete";

export default function UsuariosSistema() {
  const { action, id } = useParamsContext();

  switch (action) {
    case "edit":
    case "add": {
      return <UsuariosSistemaForm />;
    }
    case "delete":
      return <UsuariosSistemaDelete />;
    case "list":
    default:
      return <UsuariosSistemaList />;
  }
}
