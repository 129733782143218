import { useParamsContext } from "../../contexts/ParamsContext";
import ClientesForm from "./Form";
import ClientesHistory from "./History";
import ClientesList from "./List";

export default function Clientes() {
  const { action, id } = useParamsContext();

  switch (action) {
    case "edit":
    case "add": {
      return <ClientesForm />;
    }
    case "history": {
      return <ClientesHistory />;
    }
    case "list":
    default:
      return <ClientesList />;
  }
}
