import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { ServicosService } from "./Service";
const service = new ServicosService();

interface Data {
  id: string;
  status: string;
  name: boolean;
}

interface ServicosContextProps {
  data: any[];
  loading: boolean;
  reloadData: () => Promise<void>;
}

const ServicosContext = createContext<ServicosContextProps | undefined>(
  undefined
);

export const useServicosContext = () => {
  const context = useContext(ServicosContext);
  if (!context) {
    throw new Error("useServicosContext must be used within a UsersProvider");
  }
  return context;
};

export const ServicosProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  const reloadData = async () => {
    try {
      const result = await service.findAll().then((result) => {
        return result?.data || [];
      });
      
      setData(result);
    } catch (error) {
      console.error("Error fetching Servicos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reloadData();
  }, []);

  return (
    <ServicosContext.Provider value={{ data, reloadData, loading }}>
      {children}
    </ServicosContext.Provider>
  );
};
