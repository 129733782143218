import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBnonmiJ4FdZSqaQgBhGw-neCLOTMPX3kc",
  authDomain: "hemck-salon-flow.firebaseapp.com",
  databaseURL: "https://hemck-salon-flow-default-rtdb.firebaseio.com",
  projectId: "hemck-salon-flow",
  storageBucket: "hemck-salon-flow.appspot.com",
  messagingSenderId: "102587740531",
  appId: "1:102587740531:web:1458c4a5d4def520481506",
  measurementId: "G-3YT2SQ8GD6",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

export { firebaseApp, auth, analytics, firestore };
