import { useState } from "react";
import { useEstabsContext } from "../../../contexts/EstabsContext";
import { CardEstab } from "../componentes/CardEstab";

export const EstabsClientAddFirst = () => {
  const [add, setAdd] = useState(false);
  const { estabsUser } = useEstabsContext();

  return add ? (
    <div className="flex flex-wrap gap-4">
      {estabsUser.map((e, i) => (
        <div key={i} className="w-80">
          <CardEstab estab={e} />
        </div>
      ))}
    </div>
  ) : (
    <div>
      <div className="text-center text-4xl  text-gray-700 mb-6">
        Parece que você ainda não vinculou nenhum estabelecimento ao seu perfil.
      </div>

      <div className="flex flex-col items-center">
        <button
          onClick={() => setAdd(true)}
          className="btn btn-secondary btn-lg bottom-6 shadow-2xl hover:bg-blue-600 transform hover:scale-105 transition-all duration-300"
        >
          Adicionar
        </button>
      </div>
    </div>
  );
};

export const UserClientEstabAdd = () => {
  const { estabsUser } = useEstabsContext();

  return (
    <div className="flex flex-wrap gap-4">
      {estabsUser.map((e, i) => (
        <div key={i} className="w-80">
          <CardEstab estab={e} />
        </div>
      ))}
    </div>
  );
};
