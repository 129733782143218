import React, { createContext, useContext } from "react";

interface AgendaClienteParams {}

const AgendaClienteContext = createContext<AgendaClienteParams | undefined>(
  undefined
);

export const useAgendaClienteContext = () => {
  const context = useContext(AgendaClienteContext);
  if (!context) {
    throw new Error(
      "useAgendaClienteContext must be used within an AgendaClienteContext"
    );
  }
  return context;
};

export const AgendaClienteProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <AgendaClienteContext.Provider value={{}}>
      {children}
    </AgendaClienteContext.Provider>
  );
};
