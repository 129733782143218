import { useState } from "react";
import { auth } from "../../firebase";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  AuthErrorCodes,
} from "firebase/auth";
import { useSnackbar } from "@swat-sccs/react-simple-snackbar";
import RecoverPassword from "./RecoverPassword";
import CreateAccount from "./CreateAccount";

export default function LoginPage() {
  const [recoverPass, setRecoverPass] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: "tomato",
    },
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      if(error?.message?.includes("Unverified email")){
        error.code = AuthErrorCodes.UNVERIFIED_EMAIL
      }
      
      openSnackbar(getAuthErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);

    try {
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      openSnackbar(getAuthErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center h-full">
      <div className="mx-auto w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <img src="logo.png" alt="Logo" className="h-10 mx-auto mt-2" />

        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          {createAccount ? (
            <CreateAccount setCreateAccount={setCreateAccount} />
          ) : recoverPass ? (
            <RecoverPassword setRecoverPass={setRecoverPass} />
          ) : (
            <form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  disabled={loading}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Senha
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  disabled={loading}
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <a
                  href="#"
                  className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  onClick={() => setRecoverPass(true)}
                >
                  Esqueceu sua senha?
                </a>
              </div>
              <button
                type="submit"
                disabled={loading}
                className="w-full h-10 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-black text-white"
              >
                {loading ? (
                  <span className="loading loading-bars loading-sm" />
                ) : (
                  "Entrar"
                )}
              </button>

              <div className="flex items-center justify-center">
                <a
                  href="#"
                  className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  onClick={() => setCreateAccount(true)}
                >
                  Criar conta
                </a>
              </div>

              <div className="divider">ou</div>

              <button
                type="button"
                disabled={loading}
                className="w-full h-10 mt-2 hover:bg-blue-50 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
                onClick={() => signInWithGoogle()}
              >
                <div className="flex items-center justify-center">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="3 0 262 262"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                    className="mr-2"
                  >
                    <path
                      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                      fill="#4285F4"
                    />
                    <path
                      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                      fill="#34A853"
                    />
                    <path
                      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                      fill="#FBBC05"
                    />
                    <path
                      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                      fill="#EB4335"
                    />
                  </svg>{" "}
                  Entrar com Google
                </div>
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

function getAuthErrorMessage(errorCode: string): string {
  switch (errorCode) {
      case AuthErrorCodes.ADMIN_ONLY_OPERATION:
          return "Essa operação é restrita a administradores.";
      case AuthErrorCodes.ARGUMENT_ERROR:
          return "Erro de argumento. Verifique os parâmetros fornecidos.";
      case AuthErrorCodes.APP_NOT_AUTHORIZED:
          return "Este aplicativo não está autorizado a usar Firebase Authentication.";
      case AuthErrorCodes.APP_NOT_INSTALLED:
          return "O aplicativo necessário não está instalado.";
      case AuthErrorCodes.CAPTCHA_CHECK_FAILED:
          return "Falha na verificação do CAPTCHA. Tente novamente.";
      case AuthErrorCodes.CODE_EXPIRED:
          return "O código expirou. Solicite um novo código.";
      case AuthErrorCodes.CORDOVA_NOT_READY:
          return "O Cordova ainda não está pronto.";
      case AuthErrorCodes.CORS_UNSUPPORTED:
          return "O navegador não suporta CORS.";
      case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
          return "As credenciais fornecidas já estão em uso.";
      case AuthErrorCodes.CREDENTIAL_MISMATCH:
          return "As credenciais não correspondem.";
      case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
          return "Credenciais antigas. Por favor, faça login novamente.";
      case AuthErrorCodes.DEPENDENT_SDK_INIT_BEFORE_AUTH:
          return "SDK dependente inicializado antes da autenticação.";
      case AuthErrorCodes.DYNAMIC_LINK_NOT_ACTIVATED:
          return "O link dinâmico não está ativado.";
      case AuthErrorCodes.EMAIL_CHANGE_NEEDS_VERIFICATION:
          return "A mudança de e-mail precisa ser verificada.";
      case AuthErrorCodes.EMAIL_EXISTS:
          return "Este e-mail já está em uso.";
      case AuthErrorCodes.EMULATOR_CONFIG_FAILED:
          return "Falha na configuração do emulador.";
      case AuthErrorCodes.EXPIRED_OOB_CODE:
          return "O código de ação expirou.";
      case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
          return "Solicitação de popup expirada.";
      case AuthErrorCodes.INTERNAL_ERROR:
          return "Erro interno. Tente novamente mais tarde.";
      case AuthErrorCodes.INVALID_API_KEY:
          return "Chave API inválida. Verifique suas configurações.";
      case AuthErrorCodes.INVALID_APP_CREDENTIAL:
          return "Credenciais do aplicativo inválidas.";
      case AuthErrorCodes.INVALID_APP_ID:
          return "ID do aplicativo inválido.";
      case AuthErrorCodes.INVALID_AUTH:
          return "Token de usuário inválido.";
      case AuthErrorCodes.INVALID_AUTH_EVENT:
          return "Evento de autenticação inválido.";
      case AuthErrorCodes.INVALID_CERT_HASH:
          return "Hash de certificado inválido.";
      case AuthErrorCodes.INVALID_CODE:
          return "O código de verificação fornecido é inválido.";
      case AuthErrorCodes.INVALID_CONTINUE_URI:
          return "URI de continuação inválida.";
      case AuthErrorCodes.INVALID_CORDOVA_CONFIGURATION:
          return "Configuração do Cordova inválida.";
      case AuthErrorCodes.INVALID_CUSTOM_TOKEN:
          return "Token personalizado inválido.";
      case AuthErrorCodes.INVALID_DYNAMIC_LINK_DOMAIN:
          return "Domínio de link dinâmico inválido.";
      case AuthErrorCodes.INVALID_EMAIL:
          return "O e-mail fornecido é inválido.";
      case AuthErrorCodes.INVALID_EMULATOR_SCHEME:
          return "Esquema do emulador inválido.";
      case AuthErrorCodes.INVALID_IDP_RESPONSE:
          return "Resposta do provedor de identidade inválida.";
      case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
          return "Credenciais de login inválidas.";
      case AuthErrorCodes.INVALID_MESSAGE_PAYLOAD:
          return "Carga útil da mensagem inválida.";
      case AuthErrorCodes.INVALID_MFA_SESSION:
          return "Sessão de múltiplos fatores inválida.";
      case AuthErrorCodes.INVALID_OAUTH_CLIENT_ID:
          return "ID de cliente OAuth inválido.";
      case AuthErrorCodes.INVALID_OAUTH_PROVIDER:
          return "Provedor OAuth inválido.";
      case AuthErrorCodes.INVALID_OOB_CODE:
          return "O código de ação fornecido é inválido.";
      case AuthErrorCodes.INVALID_ORIGIN:
          return "Origem não autorizada.";
      case AuthErrorCodes.INVALID_PASSWORD:
          return "Senha incorreta. Tente novamente.";
      case AuthErrorCodes.INVALID_PERSISTENCE:
          return "Tipo de persistência inválido.";
      case AuthErrorCodes.INVALID_PHONE_NUMBER:
          return "O número de telefone fornecido é inválido.";
      case AuthErrorCodes.INVALID_PROVIDER_ID:
          return "ID do provedor inválido.";
      case AuthErrorCodes.INVALID_RECIPIENT_EMAIL:
          return "O e-mail do destinatário é inválido.";
      case AuthErrorCodes.INVALID_SENDER:
          return "O remetente é inválido.";
      case AuthErrorCodes.INVALID_SESSION_INFO:
          return "Informações de sessão inválidas.";
      case AuthErrorCodes.INVALID_TENANT_ID:
          return "ID do locatário inválido.";
      case AuthErrorCodes.MFA_INFO_NOT_FOUND:
          return "Informações de múltiplos fatores não encontradas.";
      case AuthErrorCodes.MFA_REQUIRED:
          return "Autenticação de múltiplos fatores necessária.";
      case AuthErrorCodes.MISSING_ANDROID_PACKAGE_NAME:
          return "Nome do pacote Android ausente.";
      case AuthErrorCodes.MISSING_APP_CREDENTIAL:
          return "Credenciais do aplicativo ausentes.";
      case AuthErrorCodes.MISSING_AUTH_DOMAIN:
          return "Domínio de autenticação ausente.";
      case AuthErrorCodes.MISSING_CODE:
          return "Código de verificação ausente.";
      case AuthErrorCodes.MISSING_CONTINUE_URI:
          return "URI de continuação ausente.";
      case AuthErrorCodes.MISSING_IFRAME_START:
          return "Início do iframe ausente.";
      case AuthErrorCodes.MISSING_IOS_BUNDLE_ID:
          return "ID do pacote iOS ausente.";
      case AuthErrorCodes.MISSING_OR_INVALID_NONCE:
          return "Nonce ausente ou inválido.";
      case AuthErrorCodes.MISSING_MFA_INFO:
          return "Informações de múltiplos fatores ausentes.";
      case AuthErrorCodes.MISSING_MFA_SESSION:
          return "Sessão de múltiplos fatores ausente.";
      case AuthErrorCodes.MISSING_PHONE_NUMBER:
          return "Número de telefone ausente.";
      case AuthErrorCodes.MISSING_SESSION_INFO:
          return "Informações de sessão ausentes.";
      case AuthErrorCodes.MODULE_DESTROYED:
          return "Módulo destruído.";
      case AuthErrorCodes.NEED_CONFIRMATION:
          return "A conta já existe com credenciais diferentes.";
      case AuthErrorCodes.NETWORK_REQUEST_FAILED:
          return "Falha na solicitação de rede. Verifique sua conexão.";
      case AuthErrorCodes.NULL_USER:
          return "Usuário nulo.";
      case AuthErrorCodes.NO_AUTH_EVENT:
          return "Nenhum evento de autenticação.";
      case AuthErrorCodes.NO_SUCH_PROVIDER:
          return "Provedor não encontrado.";
      case AuthErrorCodes.OPERATION_NOT_ALLOWED:
          return "Operação não permitida. Verifique as configurações do Firebase.";
      case AuthErrorCodes.OPERATION_NOT_SUPPORTED:
          return "Operação não suportada neste ambiente.";
      case AuthErrorCodes.POPUP_BLOCKED:
          return "Popup bloqueado pelo navegador. Permita popups e tente novamente.";
      case AuthErrorCodes.POPUP_CLOSED_BY_USER:
          return "Popup fechado pelo usuário antes da conclusão da operação.";
      case AuthErrorCodes.PROVIDER_ALREADY_LINKED:
          return "Provedor já vinculado.";
      case AuthErrorCodes.QUOTA_EXCEEDED:
          return "Cota excedida. Tente novamente mais tarde.";
      case AuthErrorCodes.REDIRECT_CANCELLED_BY_USER:
          return "Redirecionamento cancelado pelo usuário.";
      case AuthErrorCodes.REDIRECT_OPERATION_PENDING:
          return "Operação de redirecionamento pendente.";
      case AuthErrorCodes.REJECTED_CREDENTIAL:
          return "Credenciais rejeitadas.";
      case AuthErrorCodes.SECOND_FACTOR_ALREADY_ENROLLED:
          return "O segundo fator já está registrado.";
      case AuthErrorCodes.SECOND_FACTOR_LIMIT_EXCEEDED:
          return "Limite de segundos fatores excedido.";
      case AuthErrorCodes.TENANT_ID_MISMATCH:
          return "ID do locatário não corresponde.";
      case AuthErrorCodes.TIMEOUT:
          return "Tempo limite excedido. Tente novamente.";
      case AuthErrorCodes.TOKEN_EXPIRED:
          return "Token de usuário expirado.";
      case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
          return "Muitas tentativas. Por favor, tente novamente mais tarde.";
      case AuthErrorCodes.UNAUTHORIZED_DOMAIN:
          return "Domínio não autorizado. Verifique a configuração de autenticação.";
      case AuthErrorCodes.UNSUPPORTED_FIRST_FACTOR:
          return "Primeiro fator não suportado.";
      case AuthErrorCodes.UNSUPPORTED_PERSISTENCE:
          return "Tipo de persistência não suportado.";
      case AuthErrorCodes.UNSUPPORTED_TENANT_OPERATION:
          return "Operação não suportada para o locatário.";
      case AuthErrorCodes.UNVERIFIED_EMAIL:
          return "E-mail não verificado. Verifique seu e-mail e tente novamente.";
      case AuthErrorCodes.USER_CANCELLED:
          return "Operação cancelada pelo usuário.";
      case AuthErrorCodes.USER_DELETED:
          return "Usuário não encontrado.";
      case AuthErrorCodes.USER_DISABLED:
          return "Este usuário foi desativado.";
      case AuthErrorCodes.USER_MISMATCH:
          return "O usuário não corresponde.";
      case AuthErrorCodes.USER_SIGNED_OUT:
          return "O usuário foi desconectado.";
      case AuthErrorCodes.WEAK_PASSWORD:
          return "A senha fornecida é muito fraca. Escolha uma senha mais forte.";
      case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED:
          return "Armazenamento web não suportado.";
      case AuthErrorCodes.ALREADY_INITIALIZED:
          return "Firebase já inicializado.";
      case AuthErrorCodes.RECAPTCHA_NOT_ENABLED:
          return "Recaptcha não habilitado.";
      case AuthErrorCodes.MISSING_RECAPTCHA_TOKEN:
          return "Token Recaptcha ausente.";
      case AuthErrorCodes.INVALID_RECAPTCHA_TOKEN:
          return "Token Recaptcha inválido.";
      case AuthErrorCodes.INVALID_RECAPTCHA_ACTION:
          return "Ação Recaptcha inválida.";
      case AuthErrorCodes.MISSING_CLIENT_TYPE:
          return "Tipo de cliente ausente.";
      case AuthErrorCodes.MISSING_RECAPTCHA_VERSION:
          return "Versão do Recaptcha ausente.";
      case AuthErrorCodes.INVALID_RECAPTCHA_VERSION:
          return "Versão do Recaptcha inválida.";
      case AuthErrorCodes.INVALID_REQ_TYPE:
          return "Tipo de requisição inválido.";
      default:
          return "Ocorreu um erro desconhecido. Tente novamente.";
  }
}