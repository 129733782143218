import React, { useState, useEffect, useRef, ReactNode } from "react";

interface DropdownOption {
  label: string;
  onClick: () => void;
}

interface DropdownProps {
  children: ReactNode;
  options: Array<DropdownOption>;
}

export default function Dropdown({ children, options }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleOptionClick = (onClick: () => void) => {
    onClick();
    closeDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="inline-block text-left" ref={dropdownRef}>
      <div onClick={toggleDropdown}>{children}</div>

      {isOpen && (
        <div
          className="absolute mt-2 rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          style={{ zIndex: 49 }}
        >
          <div
            className=" menu shadow-lg rounded-xl"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option.onClick)}
                className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-lef rounded-xl text-start"
                role="menuitem"
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
