import TableActions from "../../components/table/Actions";
import CustomDataTable from "../../components/table/Table";

interface DataRow {
  id: number;
  nome: string;
  numeroTel: string;
}

const data: DataRow[] = [
  {
    id: 1,
    nome: "Corte de Cabelo Raspado",
    numeroTel: "+55 47 9 9284-3409",
  },
  {
    id: 2,
    nome: "Manicure Completa",
    numeroTel: "+55 11 9 8765-4321",
  },
  {
    id: 3,
    nome: "Pintura de Unhas",
    numeroTel: "+55 21 9 1234-5678",
  },
  {
    id: 4,
    nome: "Limpeza de Pele",
    numeroTel: "+55 31 9 1122-3344",
  },
  {
    id: 5,
    nome: "Massagem Relaxante",
    numeroTel: "+55 41 9 5566-7788",
  },
  {
    id: 6,
    nome: "Depilação a Laser",
    numeroTel: "+55 51 9 9988-7766",
  },
  {
    id: 7,
    nome: "Tratamento Capilar",
    numeroTel: "+55 61 9 4455-6677",
  },
  {
    id: 8,
    nome: "Pedicure",
    numeroTel: "+55 71 9 3322-1100",
  },
  {
    id: 9,
    nome: "Barba e Bigode",
    numeroTel: "+55 81 9 2233-4455",
  },
  {
    id: 10,
    nome: "Maquiagem para Festas",
    numeroTel: "+55 91 9 9988-5544",
  },  {
    id: 1,
    nome: "Corte de Cabelo Raspado",
    numeroTel: "+55 47 9 9284-3409",
  },
  {
    id: 2,
    nome: "Manicure Completa",
    numeroTel: "+55 11 9 8765-4321",
  },
  {
    id: 3,
    nome: "Pintura de Unhas",
    numeroTel: "+55 21 9 1234-5678",
  },
  {
    id: 4,
    nome: "Limpeza de Pele",
    numeroTel: "+55 31 9 1122-3344",
  },
  {
    id: 5,
    nome: "Massagem Relaxante",
    numeroTel: "+55 41 9 5566-7788",
  },
  {
    id: 6,
    nome: "Depilação a Laser",
    numeroTel: "+55 51 9 9988-7766",
  },
  {
    id: 7,
    nome: "Tratamento Capilar",
    numeroTel: "+55 61 9 4455-6677",
  },
  {
    id: 8,
    nome: "Pedicure",
    numeroTel: "+55 71 9 3322-1100",
  },
  {
    id: 9,
    nome: "Barba e Bigode",
    numeroTel: "+55 81 9 2233-4455",
  },
  {
    id: 10,
    nome: "Maquiagem para Festas",
    numeroTel: "+55 91 9 9988-5544",
  },  {
    id: 1,
    nome: "Corte de Cabelo Raspado",
    numeroTel: "+55 47 9 9284-3409",
  },
  {
    id: 2,
    nome: "Manicure Completa",
    numeroTel: "+55 11 9 8765-4321",
  },
  {
    id: 3,
    nome: "Pintura de Unhas",
    numeroTel: "+55 21 9 1234-5678",
  },
  {
    id: 4,
    nome: "Limpeza de Pele",
    numeroTel: "+55 31 9 1122-3344",
  },
  {
    id: 5,
    nome: "Massagem Relaxante",
    numeroTel: "+55 41 9 5566-7788",
  },
  {
    id: 6,
    nome: "Depilação a Laser",
    numeroTel: "+55 51 9 9988-7766",
  },
  {
    id: 7,
    nome: "Tratamento Capilar",
    numeroTel: "+55 61 9 4455-6677",
  },
  {
    id: 8,
    nome: "Pedicure",
    numeroTel: "+55 71 9 3322-1100",
  },
  {
    id: 9,
    nome: "Barba e Bigode",
    numeroTel: "+55 81 9 2233-4455",
  },
  {
    id: 10,
    nome: "Maquiagem para Festas",
    numeroTel: "+55 91 9 9988-5544",
  },  {
    id: 1,
    nome: "Corte de Cabelo Raspado",
    numeroTel: "+55 47 9 9284-3409",
  },
  {
    id: 2,
    nome: "Manicure Completa",
    numeroTel: "+55 11 9 8765-4321",
  },
  {
    id: 3,
    nome: "Pintura de Unhas",
    numeroTel: "+55 21 9 1234-5678",
  },
  {
    id: 4,
    nome: "Limpeza de Pele",
    numeroTel: "+55 31 9 1122-3344",
  },
  {
    id: 5,
    nome: "Massagem Relaxante",
    numeroTel: "+55 41 9 5566-7788",
  },
  {
    id: 6,
    nome: "Depilação a Laser",
    numeroTel: "+55 51 9 9988-7766",
  },
  {
    id: 7,
    nome: "Tratamento Capilar",
    numeroTel: "+55 61 9 4455-6677",
  },
  {
    id: 8,
    nome: "Pedicure",
    numeroTel: "+55 71 9 3322-1100",
  },
  {
    id: 9,
    nome: "Barba e Bigode",
    numeroTel: "+55 81 9 2233-4455",
  },
  {
    id: 10,
    nome: "Maquiagem para Festas",
    numeroTel: "+55 91 9 9988-5544",
  },
];

const columns = [
  {
    name: "Nome",
    selector: (row: DataRow) => row.nome,
    sortable: true,
    cell: (row: DataRow) => (
      <>
        <div className="avatar mr-2">
          <div className="w-10 rounded-full">
            <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
          </div>
        </div>
        <div style={{ textAlign: "right" }}>{row.nome}</div>
      </>
    ),
  },
  {
    name: "Último Serviço",
    right: true,
    cell: (row: DataRow) => <div>15:30</div>,
  },
  {
    name: "Ações",
    right: true,
    width: "130px",
    sortable: false,
    cell: (row: DataRow) => {
      const whatsappLink = `https://wa.me/${row.numeroTel.replace(/\D/g, "")}`;

      return (
        <TableActions
          row={row}
          options={[
            {
              type: "history",
            },
          ]}
        >
          <li className="contents">
            <a
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center py-0"
            >
              <i className="fa-brands fa-whatsapp text-lg m-0"></i>
            </a>
          </li>
        </TableActions>
      );
    },
  },
];

export default function ClientesList() {
  return (
    <CustomDataTable
      columns={columns}
      data={data}
      pagination
      highlightOnHover
      striped
    />
  );
}
