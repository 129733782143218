import TableActions from "../../../../components/table/Actions";
import EstabelecimentosListStatus from "./Status";

export const columns = [
  {
    name: "Situação",
    cell: (row: any) => (
      <EstabelecimentosListStatus status={row.status} id={row.id} />
    ),
  },
  {
    name: "Nome",
    selector: (row: any) => row.name,
    sortable: true,
    cell: (row: any) => <div style={{ textAlign: "right" }}>{row.name}</div>,
  },
  {
    name: "Ações",
    right: true,
    sortable: false,
    cell: (row: any) => (
      <TableActions
        row={row}
        options={[
          {
            type: "edit",
          },
          {
            type: "delete",
          },
        ]}
      />
    ),
  },
];
