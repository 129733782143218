import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useParamsContext } from "../../../contexts/ParamsContext";
import { AusenciasProvider } from "../../ausencias/Context";
import { AtendentesService } from "../../atendentes/Service";
import { useAuthContext } from "../../../contexts/AuthContext";
const service = new AtendentesService();

interface AgendaParams {
  loading: boolean;
  date: moment.Moment;
  handlePreviousDay: () => void;
  handleNextDay: () => void;
  setDate: any;
  schedules: Schedule[] | undefined;
  selectedSchedule: Schedule | undefined;
  atendentes: any;
  filter: any;
  setFilter: any;
}

const AgendaContext = createContext<AgendaParams | undefined>(undefined);

export const useAgendaContext = () => {
  const context = useContext(AgendaContext);
  if (!context) {
    throw new Error("useAgendaContext must be used within an AgendaProvider");
  }
  return context;
};

export const AgendaProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { action, id, clear } = useParamsContext();

  const [date, setDate] = useState(
    moment(sessionStorage.getItem("dateAgenda") || moment())
  );
  const [loading, setLoading] = useState(true);
  const [selectedSchedule, setSelectedSchedule] = useState<
    Schedule | undefined
  >(undefined);

  const [atendentes, setAtendentes] = useState([]);
  const [filter, setFilter] = useState<any>({});

  const [schedules, setSchedules] = useState<Schedule[] | undefined>(undefined);

  const handlePreviousDay = () => {
    if (!loading) setDate((prevDate) => moment(prevDate).subtract(1, "days"));
  };

  const handleNextDay = () => {
    if (!loading) setDate((prevDate) => moment(prevDate).add(1, "days"));
  };

  useEffect(() => {
    setLoading(true);
    clear();

    const schedules = scheduleData.filter((agendamento: any) => {
      if (
        filter?.atendentesIds?.length > 0 &&
        !filter?.atendentesIds?.includes(agendamento?.attendant?.uid)
      ) {
        return false;
      }
      return (
        moment(agendamento.start).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD")
      );
    });

    setTimeout(() => {
      setLoading(false);
    }, 200);

    setSchedules(schedules);
    sessionStorage.setItem("dateAgenda", date.toString());
  }, [date, filter]);

  useEffect(() => {
    if (action === "view" && !!id) {
      setSelectedSchedule(schedules?.find((e) => e.id === id));
    } else {
      setSelectedSchedule(undefined);
    }
  }, [action, id, schedules]);

  useEffect(() => {
    service
      .getAll()
      .then((response) => {
        console.log(response.data.data);

        setAtendentes(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    const lastFilter = localStorage.getItem("filterAgenda");

    if (lastFilter) {
      setFilter(JSON.parse(lastFilter));
    }
  }, []);

  return (
    <AgendaContext.Provider
      value={{
        loading,
        date,
        handlePreviousDay,
        handleNextDay,
        schedules,
        selectedSchedule,
        setDate,
        atendentes,
        filter,
        setFilter,
      }}
    >
      <AusenciasProvider>{children}</AusenciasProvider>
    </AgendaContext.Provider>
  );
};

export enum ScheduleStatusEnum {
  Pending = 0,
  Confirmed = 1,
  Completed = 2,
  Canceled = 3,
}

export interface Schedule {
  id: string;
  start: Date;
  end: Date;
  status: ScheduleStatusEnum;
  observation?: string;
  service: {
    name: string;
    price: string;
  };
  attendant: {
    uid: string;
    name: string;
    email: string;
    phoneNumber?: string;
    profileImageUrl?: string;
    whatsappLink?: string;
  };
  client: {
    uid: string;
    name: string;
    email: string;
    phoneNumber?: string;
    profileImageUrl?: string;
    whatsappLink?: string;
    amountSchedules?: Number;
  };
  assessment?: {
    value: number;
    date: Date;
    observation?: string;
  };
  cancellation?: {
    observation: string;
    date: Date;
  };
  accessibility?: {
    observation: string;
  };
  createdAt: Date;
}

const now = new Date();

const images = [
  "https://s2-techtudo.glbimg.com/Pc2RjVQrBQSvUQFDGhaAdyYG9s8=/0x0:300x200/984x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_08fbf48bc0524877943fe86e43087e7a/internal_photos/bs/2021/F/Y/5bfUFsR3aY2NLh7Umaeg/2012-05-11-rob-may-backupify.jpg",
  "https://i.pinimg.com/originals/4f/73/3b/4f733b83724e86f43c759de191f7e9fc.jpg",
  "https://conteudo.imguol.com.br/c/entretenimento/57/2021/01/28/desinfluencers-novo-perfil-que-ocupa-as-redes-e-mais-diverso-e-real-1611856196074_v2_1080x1349.jpg",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXYkIvwDU6jkxwULJJhFBd8M3jEfQ4O0xzUHD6nytFY1IzBDbIfUE3sWVg_NHIGlqNhDo&usqp=CAU",
  "https://i.pinimg.com/550x/ef/f3/db/eff3dbf3ddb97b7232e742a97206cc93.jpg",
];

const getRandomImage = () => images[Math.floor(Math.random() * images.length)];

const scheduleData: Schedule[] = [
  {
    id: "7",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0),
    status: ScheduleStatusEnum.Canceled,
    service: {
      name: "Corte de cabelo raspado",
      price: "R$ 50,00",
    },
    attendant: {
      uid: "I9hrb4eV0aNkydAgaOhQbeGodm92",
      name: "Pedro Silva",
      email: "pedro@dsadas.com",
    },
    client: {
      uid: "I9hrb4eV0aNkydAgaOhQbeGodm92",
      name: "Otávio Hemckmeier",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "otavio.hemck@gmail.com",
      phoneNumber: "+5547992843409",
      amountSchedules: 9,
    },
    assessment: {
      value: 1,
      date: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16, 0),
      observation: "Poderia ter ficado melhor",
    },
    cancellation: {
      observation: "Não pude ir",
      date: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0),
    },
    createdAt: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      10,
      30
    ),
  },
  {
    id: "8",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0),
    status: ScheduleStatusEnum.Confirmed,
    service: {
      name: "Tratamento capilar",
      price: "R$ 150,00",
    },
    attendant: {
      uid: "1",
      name: "Maria Oliveira",
      email: "maria@exemplo.com",
    },
    client: {
      uid: "1",
      name: "João Pereira",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "joao.pereira@gmail.com",
      phoneNumber: "+5547992745698",
      amountSchedules: 5,
    },
    assessment: {
      value: 4,
      date: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 0),
      observation: "Muito bom!",
    },
    createdAt: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      15,
      0
    ),
  },
  {
    id: "9",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0),
    status: ScheduleStatusEnum.Completed,
    service: {
      name: "Manicure",
      price: "R$ 80,00",
    },
    attendant: {
      uid: "1",
      name: "Carlos Souza",
      email: "carlos@exemplo.com",
    },
    client: {
      uid: "1",
      name: "Ana Lima",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "ana.lima@gmail.com",
      phoneNumber: "+5547992841234",
      amountSchedules: 3,
    },
    assessment: {
      value: 5,
      date: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 18, 0),
      observation: "Excelente!",
    },
    createdAt: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0),
  },
  {
    id: "10",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0),
    status: ScheduleStatusEnum.Pending,
    service: {
      name: "Coloração",
      price: "R$ 200,00",
    },
    attendant: {
      uid: "1",
      name: "Fernanda Lima",
      email: "fernanda@exemplo.com",
    },
    client: {
      uid: "1",
      name: "Marcos Vinicius",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "marcos.vinicius@gmail.com",
      phoneNumber: "+5547992845678",
      amountSchedules: 2,
    },
    createdAt: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      11,
      0
    ),
  },
  {
    id: "11",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 0),
    status: ScheduleStatusEnum.Pending,
    service: {
      name: "Depilação",
      price: "R$ 100,00",
    },
    attendant: {
      uid: "1",
      name: "Lucas Dias",
      email: "lucas@exemplo.com",
    },
    client: {
      uid: "1",
      name: "Beatriz Souza",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "beatriz.souza@gmail.com",
      phoneNumber: "+5547992745690",
      amountSchedules: 4,
    },
    createdAt: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0),
  },
  {
    id: "12",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0),
    status: ScheduleStatusEnum.Confirmed,
    service: {
      name: "Corte infantil",
      price: "R$ 30,00",
    },
    attendant: {
      uid: "1",
      name: "Aline Santos",
      email: "aline@exemplo.com",
    },
    client: {
      uid: "1",
      name: "Gustavo Lima",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "gustavo.lima@gmail.com",
      phoneNumber: "+5547992841234",
      amountSchedules: 1,
    },
    createdAt: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      9,
      30
    ),
  },
  {
    id: "13",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0),
    status: ScheduleStatusEnum.Completed,
    service: {
      name: "Pintura",
      price: "R$ 250,00",
    },
    attendant: {
      uid: "1",
      name: "Rafael Moreira",
      email: "rafael@exemplo.com",
    },
    client: {
      uid: "1",
      name: "Clara Fernandes",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "clara.fernandes@gmail.com",
      phoneNumber: "+5547992845678",
      amountSchedules: 6,
    },
    createdAt: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      10,
      30
    ),
  },
  {
    id: "14",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16, 0),
    status: ScheduleStatusEnum.Confirmed,
    service: {
      name: "Escova progressiva",
      price: "R$ 300,00",
    },
    attendant: {
      uid: "1",
      name: "Bruna Almeida",
      email: "bruna@exemplo.com",
    },
    client: {
      uid: "1",
      name: "Fernando Cardoso",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "fernando.cardoso@gmail.com",
      phoneNumber: "+5547992841234",
      amountSchedules: 8,
    },
    createdAt: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      12,
      0
    ),
  },
  {
    id: "15",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14, 0),
    status: ScheduleStatusEnum.Pending,
    service: {
      name: "Alisamento",
      price: "R$ 180,00",
    },
    attendant: {
      uid: "1",
      name: "Patrícia Gomes",
      email: "patricia@exemplo.com",
    },
    client: {
      uid: "1",
      name: "Ricardo Silva",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "ricardo.silva@gmail.com",
      phoneNumber: "+5547992845678",
      amountSchedules: 10,
    },
    createdAt: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      10,
      0
    ),
  },
  {
    id: "16",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 0),
    status: ScheduleStatusEnum.Completed,
    service: {
      name: "Hidratação",
      price: "R$ 120,00",
    },
    attendant: {
      uid: "1",
      name: "Joana Costa",
      email: "joana@exemplo.com",
    },
    client: {
      uid: "1",
      name: "Luciana Martins",
      profileImageUrl: getRandomImage(),
      whatsappLink:
        "https://translate.google.com/?hl=pt&sl=en&tl=pt&text=assignee&op=translate",
      email: "luciana.martins@gmail.com",
      phoneNumber: "+5547992841234",
      amountSchedules: 7,
    },
    createdAt: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      8,
      30
    ),
  },
];
