import { Route, Routes } from "react-router-dom";
import "./App.css";
import PageLoading from "./components/PageLoading";
import { useAppContext } from "./contexts/AppContext";
import { useAuthContext } from "./contexts/AuthContext";
import DashboardPage from "./pages/dashboard/Dashboard";
import LoginPage from "./pages/login/Login";
import VerificationAccount from "./pages/verificationAccount/VerificationAccount";

export default function App() {
  const { user } = useAuthContext();
  const { loadingApp } = useAppContext();

  if (loadingApp) {
    return (
      <div className="h-screen">
        <PageLoading />
        <footer className="footer footer-center p-10 text-base-content rounded">
          <aside>
            <p>Copyright © {new Date().getFullYear()} - Salon Flow</p>
          </aside>
        </footer>
      </div>
    );
  }

  return (
    <div className="h-screen">
      <main className="flex-grow">
        <Routes>
          <Route path="/verification-account" element={<VerificationAccount />} />
          <Route path="*" element={!!user?.uid ? <DashboardPage /> : <LoginPage />} />
        </Routes>
      </main>
      <footer className="footer footer-center p-10 text-base-content rounded">
        <aside>
          <p>Copyright © {new Date().getFullYear()} - Salon Flow</p>
        </aside>
      </footer>
    </div>
  );
}
