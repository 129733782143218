import { api } from "../../AxiosConfig";

class AusenciasService {
  create(data: any) {
    return api.post("/ausencias/create", data);
  }

  findAll() {
    return api.get("/ausencias/findAll");
  }

  findOne(id: string) {
    return api.post("/ausencias/findOne", { id });
  }

  update(id: string, update: any) {
    return api.post("/ausencias/update", { id, update });
  }

  delete(id: string) {
    return api.post("/ausencias/delete", { id });
  }
}

export { AusenciasService };
