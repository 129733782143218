import { api } from "../../AxiosConfig";

class AtendentesService {
  create(data: any) {
    return api.post("users/atendentes/create", data);
  }

  getAll() {
    return api.get("users/atendentes/getAll");
  }

  disable(uid: string) {
    return api.post("users/atendentes/disable", { uid });
  }

  active(uid: string) {
    return api.post("users/atendentes/active", { uid });
  }

  delete(uid: string) {
    return api.post("users/atendentes/delete", { uid });
  }
}

export { AtendentesService };
