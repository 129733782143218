import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { EstabelecimentosService } from "../../../estabelecimentos/system-admin/Service";
import { useEstabsContext } from "../../../../contexts/EstabsContext";
import { useAppContext } from "../../../../contexts/AppContext";
import { AusenciasService } from "../../../ausencias/Service";

const serviceEstab = new EstabelecimentosService();
const serviceAusencias = new AusenciasService();

interface TimeSlot {
  start: number;
  end: number;
}

interface Horarios {
  [key: string]: TimeSlot[];
}

const initialHorarios: Horarios = {
  segunda: [],
  terca: [],
  quarta: [],
  quinta: [],
  sexta: [],
  sabado: [],
  domingo: [],
};

export interface Ausencias {
  id: string;
  start: Date;
  end: Date;
  atendentes: string[];
  motivo: string;
}

interface AgendaSettingsContextProps {
  daysOpen: number;
  setDaysOpen: React.Dispatch<React.SetStateAction<number>>;
  horarios: Horarios;
  setHorarios: React.Dispatch<React.SetStateAction<Horarios>>;
  submit: () => void;
  loading: boolean;
}

const validateHorarios = (horarios: Horarios): Horarios => {
  const validatedHorarios: Horarios = {};
  for (const [day, slots] of Object.entries(horarios)) {
    validatedHorarios[day] = slots.filter(
      (slot) => slot.start > 0 && slot.end > 0 && slot.start < slot.end
    );
  }
  return validatedHorarios;
};

const AgendaSettingsContext = createContext<
  AgendaSettingsContextProps | undefined
>(undefined);

const AgendaSettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { errorSnackbar, successSnackbar } = useAppContext();

  const [loading, setLoading] = useState(false);
  const { estabSession, reloadEstab } = useEstabsContext();
  const [daysOpen, setDaysOpen] = useState(10);
  const [horarios, setHorarios] = useState<Horarios>(initialHorarios);

  const submit = async () => {
    try {
      setLoading(true);

      const validatedHorarios = validateHorarios(horarios);

      await serviceEstab.update(estabSession.id, {
        agenda: {
          daysOpen,
          horarios: validatedHorarios,
        },
      });

      successSnackbar("Agenda editada com sucesso!");
      reloadEstab();
    } catch (e) {
      errorSnackbar("Ocorreu um erro ao editar a agenda.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (estabSession) {
      setDaysOpen(estabSession?.agenda?.daysOpen || 0);
      setHorarios({
        ...initialHorarios,
        ...(estabSession?.agenda?.horarios || {}),
      });
    }
  }, [estabSession]);

  return (
    <AgendaSettingsContext.Provider
      value={{
        daysOpen,
        setDaysOpen,
        horarios,
        setHorarios,
        submit,
        loading,
      }}
    >
      {children}
    </AgendaSettingsContext.Provider>
  );
};

const useAgendaSettingsContext = () => {
  const context = useContext(AgendaSettingsContext);
  if (context === undefined) {
    throw new Error(
      "useAgendaSettingsContext must be used within an AgendaSettingsProvider"
    );
  }
  return context;
};

export { AgendaSettingsProvider, useAgendaSettingsContext };
