export const getInitialName = (name = "") => {
  const nameArray = name.trim().split(" ");
  const initials =
    nameArray.length > 1
      ? nameArray[0][0].toUpperCase() +
        nameArray[nameArray.length - 1][0].toUpperCase()
      : nameArray[0][0].toUpperCase();
  return initials;
};

export const formatToReal = (
  valor: number,
  casasDecimais: number = 2
): string => {
  return valor
    .toFixed(casasDecimais)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
