import { GeralEstabelecimento } from "./Geral";
import { ContatoEstabelecimento } from "./Contato";
import { useEstabFormContext } from "./Context";
import { EnderecoEstabelecimento } from "./Address";
import { ImageEstab } from "./ImageEstab";

export const EstabelecimentoForm = () => {
  const { imageId, setImageId, handleSubmit, loading } = useEstabFormContext();

  return (
    <div className="container mx-auto p-4">
      <form onSubmit={handleSubmit}>
        <div className="form-control mb-4 flex justify-center">
          <ImageEstab
            key={`${!!imageId.length}`}
            imageId={imageId}
            setImageId={setImageId}
          />
        </div>

        <div role="tablist" className="tabs tabs-bordered">
          <input
            type="radio"
            name="my_tabs_1"
            role="tab"
            className="tab"
            aria-label="Geral"
            defaultChecked
          />
          <div role="tabpanel" className="tab-content px-5 pt-5">
            <GeralEstabelecimento />
          </div>

          <input
            type="radio"
            name="my_tabs_1"
            role="tab"
            className="tab"
            aria-label="Endereço"
          />
          <div role="tabpanel" className="tab-content px-5 pt-5">
            <EnderecoEstabelecimento />
          </div>

          <input
            type="radio"
            name="my_tabs_1"
            role="tab"
            className="tab"
            aria-label="Contato"
          />
          <div role="tabpanel" className="tab-content px-5 pt-5">
            <ContatoEstabelecimento />
          </div>
        </div>

        <div className="divider" />

        <button className="btn shadow-md" type="submit" disabled={loading}>
          Salvar Alterações
        </button>
      </form>
    </div>
  );
};
