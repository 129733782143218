import { useEstabFormContext } from "./Context";
import PhoneNumber from "../../../../components/inputs/PhoneNumber";

export function ContatoEstabelecimento() {
  const {
    phoneNumber,
    setPhoneNumber,
    isPhoneNumberWhatsapp,
    setIsPhoneNumberWhatsapp,
    instagram,
    setInstagram,
    facebook,
    setFacebook,
    email,
    setEmail,
    website,
    setWebsite,
  } = useEstabFormContext();

  return (
    <>
      <PhoneNumber
        value={phoneNumber}
        setValue={setPhoneNumber}
        label="Número de telefone"
      />

      <div className="form-control mb-4">
        <label className="label" htmlFor="instagram">
          <span className="label-text">Whatsapp?</span>
        </label>
        <input
          type="checkbox"
          className="toggle toggle-sm"
          checked={isPhoneNumberWhatsapp}
          onChange={() => setIsPhoneNumberWhatsapp(!isPhoneNumberWhatsapp)}
        />
      </div>

      <div className="form-control mb-4">
        <label className="label" htmlFor="instagram">
          <span className="label-text">Instagram:</span>
        </label>
        <input
          type="url"
          id="instagram"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
          className="input input-bordered"
        />
      </div>

      <div className="form-control mb-4">
        <label className="label" htmlFor="facebook">
          <span className="label-text">Facebook:</span>
        </label>
        <input
          type="url"
          id="facebook"
          value={facebook}
          onChange={(e) => setFacebook(e.target.value)}
          className="input input-bordered"
        />
      </div>
      <div className="form-control mb-4">
        <label className="label" htmlFor="email">
          <span className="label-text">Email:</span>
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input input-bordered"
        />
      </div>
      <div className="form-control mb-4">
        <label className="label" htmlFor="website">
          <span className="label-text">Site:</span>
        </label>
        <input
          type="url"
          id="website"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          className="input input-bordered"
        />
      </div>
    </>
  );
}
