import { useState } from "react";
import { useEstabsContext } from "../../../contexts/EstabsContext";
import { urlFile } from "../../../AxiosConfig";
import { ToggleAddEstabUserBtn } from "../client/AddEstabUser";
import { ViewEstabInfoModal } from "./ViewEstabInfoModal";
import CarouselImgs from "./CarouselImg";

export const CardEstab = ({ estab }: any) => {
  const { userClientEstabs } = useEstabsContext();
  const [openInfoEstab, setOpenInfoEstab] = useState(false);

  return (
    <div className="card bg-base-100 shadow-xl hover:shadow-2xl">
      <div onClick={() => setOpenInfoEstab(true)} className="cursor-pointer">
        {estab?.imageId && (
          <figure className="p-4">
            <img src={`${urlFile}/${estab?.imageId}`} alt="Shoes" />
          </figure>
        )}
        <div className="card-body">
          <h2 className="card-title">
            {estab?.name}
            <div className="badge badge-secondary">NEW</div>
          </h2>
          <p>{estab?.description}</p>

          <p className="text-xs">
            {[
              estab?.address?.street,
              estab?.address?.number,
              estab?.address?.complement,
              estab?.address?.neighborhood,
              estab?.address?.city,
              estab?.address?.state,
              estab?.address?.zipCode,
            ]
              .filter(Boolean)
              .join(", ")}
          </p>
        </div>
      </div>

      <div className="flex justify-between items-center px-5 pb-5">
        {userClientEstabs.some((e) => e._id === estab._id) ? (
          <button className="btn btn-sm btn-secondary">AGENDAR HORÁRIO</button>
        ) : (
          <ToggleAddEstabUserBtn estabId={estab._id} />
        )}

        <div className="card-actions" style={{ alignItems: "center" }}>
          <button
            onClick={() => {
              if (estab?.phoneNumber && estab?.isPhoneNumberWhatsapp) {
                const phoneNumber = estab.phoneNumber.replace(/\D/g, "");
                window.open(
                  `https://wa.me/${phoneNumber}`,
                  "_blank",
                  "noopener noreferrer"
                );
              }
            }}
            className={"btn btn-circle btn-xs"}
            disabled={!estab?.phoneNumber || !estab?.isPhoneNumberWhatsapp}
          >
            <i className="fa-brands fa-whatsapp"></i>
          </button>

          <button
            onClick={() => {
              if (estab?.location) {
                window.open(estab.location, "_blank", "noopener noreferrer");
              }
            }}
            className={"btn btn-circle btn-xs"}
            disabled={!estab?.location}
          >
            <i className="fa-solid fa-location-dot"></i>
          </button>

          {userClientEstabs.some((e) => e._id === estab._id) && (
            <ToggleAddEstabUserBtn estabId={estab._id} small />
          )}
        </div>
      </div>

      {openInfoEstab && (
        <ViewEstabInfoModal
          estabId={estab._id}
          onClose={() => setOpenInfoEstab(false)}
        />
      )}
    </div>
  );
};
