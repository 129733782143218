import { useAgendaSettingsContext } from "./Context";

export default function AgendaSettingsGeral() {
  const { daysOpen, setDaysOpen, submit, loading } = useAgendaSettingsContext();

  return (
    <div>
      <div className="form-control mb-4">
        <label className="label" htmlFor="operatingHours">
          <span className="label-text">Dias que a agenda ficará aberta</span>
        </label>
        <input
          type="number"
          value={daysOpen}
          onChange={(e) => setDaysOpen(parseInt(e.target.value))}
          id="operatingHours"
          className="input input-bordered"
        />
      </div>

      <div className="divider" />

      <button
        onClick={submit}
        disabled={loading}
        className="btn shadow-md"
        type="submit"
      >
        Salvar Alterações
      </button>
    </div>
  );
}
