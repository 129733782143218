import React, { createContext, useContext, useEffect, useState } from "react";
import { useEstabsContext } from "../../../../contexts/EstabsContext";
import { EstabelecimentosService } from "../../../estabelecimentos/system-admin/Service";
import { useAppContext } from "../../../../contexts/AppContext";
const service = new EstabelecimentosService();

interface EstabFormParams {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  imageId: string;
  setImageId: React.Dispatch<React.SetStateAction<string>>;
  cnpj: string;
  setCnpj: React.Dispatch<React.SetStateAction<string>>;
  street: string;
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  number: string;
  setNumber: React.Dispatch<React.SetStateAction<string>>;
  complement: string;
  setComplement: React.Dispatch<React.SetStateAction<string>>;
  neighborhood: string;
  setNeighborhood: React.Dispatch<React.SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  zipCode: string;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  website: string;
  setWebsite: React.Dispatch<React.SetStateAction<string>>;
  facebook: string;
  setFacebook: React.Dispatch<React.SetStateAction<string>>;
  instagram: string;
  setInstagram: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  isPhoneNumberWhatsapp: boolean;
  setIsPhoneNumberWhatsapp: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const EstabFormContext = createContext<EstabFormParams | undefined>(undefined);

export const useEstabFormContext = () => {
  const context = useContext(EstabFormContext);
  if (!context) {
    throw new Error(
      "useEstabFormContext must be used within a EstabFormProvider"
    );
  }
  return context;
};

export const EstabFormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { estabSession, reloadEstab } = useEstabsContext();
  const { errorSnackbar, successSnackbar } = useAppContext();

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [description, setDescription] = useState("");
  const [imageId, setImageId] = useState("");

  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberWhatsapp, setIsPhoneNumberWhatsapp] = useState(false);

  useEffect(() => {
    if (!!estabSession?._id) {
      setName(estabSession?.name || "");
      setCnpj(estabSession?.cnpj || "");
      setStreet(estabSession?.address?.street || "");
      setNumber(estabSession?.address?.number || "");
      setComplement(estabSession?.address?.complement || "");
      setNeighborhood(estabSession?.address?.neighborhood || "");
      setCity(estabSession?.address?.city || "");
      setState(estabSession?.address?.state || "");
      setZipCode(estabSession?.address?.zipCode || "");
      setDescription(estabSession?.description || "");
      setEmail(estabSession?.email || "");
      setWebsite(estabSession?.website || "");
      setFacebook(estabSession?.facebook || "");
      setInstagram(estabSession?.instagram || "");
      setPhoneNumber(estabSession?.phoneNumber || "");
      setIsPhoneNumberWhatsapp(estabSession?.isPhoneNumberWhatsapp || false);
      setImageId(estabSession?.imageId || "");

      setLoading(false);
    }
  }, [estabSession]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);

      await service.update(estabSession._id, {
        name,
        cnpj,
        address: {
          street,
          number,
          complement,
          neighborhood,
          city,
          state,
          zipCode,
        },
        description,
        imageId,
        email,
        website,
        facebook,
        instagram,
        phoneNumber,
        isPhoneNumberWhatsapp,
      });

      successSnackbar("Estabelecimento alterado com sucesso!");
      reloadEstab();
    } catch (e) {
      console.log(e);
      errorSnackbar("Ocorreu um erro ao alterar o estabelecimento.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <EstabFormContext.Provider
      value={{
        name,
        setName,
        cnpj,
        setCnpj,
        street,
        setStreet,
        number,
        setNumber,
        complement,
        setComplement,
        neighborhood,
        setNeighborhood,
        city,
        setCity,
        state,
        setState,
        zipCode,
        setZipCode,
        description,
        setDescription,
        email,
        setEmail,
        website,
        setWebsite,
        facebook,
        setFacebook,
        instagram,
        setInstagram,
        phoneNumber,
        setPhoneNumber,
        imageId,
        setImageId,
        handleSubmit,
        loading,
        setLoading,
        isPhoneNumberWhatsapp,
        setIsPhoneNumberWhatsapp
      }}
    >
      {children}
    </EstabFormContext.Provider>
  );
};
