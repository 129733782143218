import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/AppContext";
import { menus } from "../../../routes";
import { useEstabsContext } from "../../../contexts/EstabsContext";
import { useAuthContext } from "../../../contexts/AuthContext";

export default function Menu() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { shortMenu } = useAppContext();
  const { switchEstab, estabsUser, estabSession } = useEstabsContext();

  return (
    <div className={`w-min pl-2`}>
      <ul className="menu rounded-box p-0">
        {menus(user).map(({ title, icon, path }, i) => (
          <div
            key={i}
            className={`${shortMenu && "tooltip tooltip-right"}`}
            data-tip={shortMenu && title}
            onClick={() => {
              navigate(path);
            }}
          >
            <li className="pb-1">
              <a className="flex items-center">
                <i className={`${icon} text-lg`}></i>
                {!shortMenu && title}
              </a>
            </li>
          </div>
        ))}

        {user?.role !== "client" &&
          !!estabsUser?.length &&
          estabsUser?.length > 1 && (
            <>
              <div
                className="divider z-50"
                style={{ height: 0, margin: "5px 0 7px 0" }}
              ></div>
              <div
                className={`${shortMenu && "tooltip tooltip-right"} dropdown`}
                data-tip={shortMenu && estabSession?.name}
              >
                <li tabIndex={0} className="pb-1">
                  <a className="flex items-center">
                    <i className="fa-solid fa-building text-lg"></i>
                    {!shortMenu && estabSession?.name}
                    <i className="fa-solid fa-sort-down ml-1"></i>
                  </a>
                  <ul className="dropdown-content menu shadow-lg bg-base-100 rounded-box w-52 mt-10 z-50">
                    {estabsUser.map((e, i): any => (
                      <li key={i}>
                        <a onClick={() => switchEstab(e?._id)}>{e?.name}</a>
                      </li>
                    ))}
                  </ul>
                </li>
              </div>
            </>
          )}
      </ul>
    </div>
  );
}
