import { useEstabsContext } from "../../../contexts/EstabsContext";
import { useParamsContext } from "../../../contexts/ParamsContext";
import { CardEstab } from "../componentes/CardEstab";
import { EstabsClientAddFirst, UserClientEstabAdd } from "./AddFirst";

export default function EstabelecimentosClient() {
  const { action } = useParamsContext();
  const { userClientEstabs } = useEstabsContext();

  switch (action) {
    case "add":
      return <UserClientEstabAdd />;

    default: {
      if (!userClientEstabs?.length) return <EstabsClientAddFirst />;
  
      return (
        <div className="flex flex-wrap gap-4">
          {userClientEstabs.map((e, i) => (
            <div key={i} className="w-80">
              <CardEstab estab={e} />
            </div>
          ))}
        </div>
      );
    }
  }
}
