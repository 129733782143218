import React, { useEffect, useState } from "react";
import { useParamsContext } from "../../../contexts/ParamsContext";
import { EstabelecimentosService } from "./Service";
import { useAppContext } from "../../../contexts/AppContext";
import { useEstabsContext } from "./Context";

const service = new EstabelecimentosService();

export default function EstabelecimentosForm() {
  const { clear, id } = useParamsContext();
  const { data, reloadData } = useEstabsContext();
  const { successSnackbar, errorSnackbar } = useAppContext();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const doc: any = data?.find((e) => e._id === id);

    if (!!doc) {
      setName(doc.name);
      setDescription(doc.description);
    }
  }, [id, data]);

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const estabelecimento: any = { name };

      if (!!description) {
        estabelecimento.description = description;
      }

      if (!!id) {
        await service.update(id, estabelecimento);
        successSnackbar("Estabelecimento editado com sucesso!");
      } else {
        await service.create(estabelecimento);
        successSnackbar("Estabelecimento criado com sucesso!");
      }

      reloadData();
    } catch (e) {
      console.log(e);
      errorSnackbar("Ocorreu um erro ao criar o estabelecimento.");
    } finally {
      clear();
    }
  };

  return (
    <div>
      <form onSubmit={submit}>
        <label className="form-control">
          <div className="label">
            <span className="label-text">Nome</span>
          </div>
          <input
            type="text"
            className="input input-bordered"
            placeholder="Nome do estabelecimento"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label className="form-control">
          <div className="label">
            <span className="label-text">Descrição</span>
          </div>
          <textarea
            className="textarea textarea-bordered"
            placeholder="Descrição do Estabelecimento"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </label>

        <div className="divider"></div>

        <button className="btn shadow-md" type="submit">
          {!!id ? "Editar" : "Adicionar"}
        </button>
      </form>
    </div>
  );
}
