import { useEffect, useState } from "react";
import { Modal } from "../../../components/Modal";
import { EstabelecimentosService } from "../system-admin/Service";
import { FilePreviewImage } from "../../../components/files/PreviewImage";
import { DurationService } from "../../servicos/list/Duration";
import { formatToReal } from "../../../components/utils";
import { urlFile } from "../../../AxiosConfig";
import { ToggleAddEstabUserBtn } from "../client/AddEstabUser";
import { useEstabsContext } from "../../../contexts/EstabsContext";
const service = new EstabelecimentosService();
interface ServicosViewModalProps {
  estabId: string;
  onClose: () => void;
}

export const ViewEstabInfoModal = ({
  estabId,
  onClose,
}: ServicosViewModalProps) => {
  const [loading, setLoading] = useState(true);
  const [estab, setEstab] = useState({} as any);
  const { userClientEstabs } = useEstabsContext();

  const loadServicos = async (estabId: string) => {
    try {
      setLoading(true);
      const { data } = await service.getInfoById(estabId);
      setEstab(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadServicos(estabId);
  }, [estabId]);
  return (
    <Modal onClose={onClose}>
      <div className="overflow-x-auto p-2 bg-white bg-opacity-60 rounded-xl">
        {loading ? (
          <span className="loading loading-bars loading-sm m-10" />
        ) : (
          <div className="grid grid-cols-1 gap-4">
            <div className="stats shadow">
              <div className="stat">
                <div className="flex justify-between">
                  <div className="avatar">
                    <div className="mask mask-squircle h-32">
                      <img
                        src={`${urlFile}/${estab?.imageId}`}
                        alt="Logo do Estabelecimento"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="card-body" style={{ paddingTop: 0 }}>
                      <h2 className="card-title">
                        {estab?.name}
                        <div className="badge badge-secondary">NEW</div>
                      </h2>
                      <p>{estab?.description}</p>

                      <p className="text-xs">
                        {[
                          estab?.address?.street,
                          estab?.address?.number,
                          estab?.address?.complement,
                          estab?.address?.neighborhood,
                          estab?.address?.city,
                          estab?.address?.state,
                          estab?.address?.zipCode,
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </p>
                      <div className="flex justify-between items-center mt-3">
                        <div className="mr-6">
                          {userClientEstabs.some((e) => e._id === estab._id) ? (
                            <button className="btn btn-sm btn-secondary">
                              AGENDAR HORÁRIO
                            </button>
                          ) : (
                            <ToggleAddEstabUserBtn estabId={estab._id} />
                          )}
                        </div>

                        <div
                          className="card-actions"
                          style={{ alignItems: "center" }}
                        >
                          <button
                            onClick={() => {
                              if (
                                estab?.phoneNumber &&
                                estab?.isPhoneNumberWhatsapp
                              ) {
                                const phoneNumber = estab.phoneNumber.replace(
                                  /\D/g,
                                  ""
                                );
                                window.open(
                                  `https://wa.me/${phoneNumber}`,
                                  "_blank",
                                  "noopener noreferrer"
                                );
                              }
                            }}
                            className={"btn btn-circle btn-xs"}
                            disabled={
                              !estab?.phoneNumber ||
                              !estab?.isPhoneNumberWhatsapp
                            }
                          >
                            <i className="fa-brands fa-whatsapp"></i>
                          </button>

                          <button
                            onClick={() => {
                              if (estab?.location) {
                                window.open(
                                  estab.location,
                                  "_blank",
                                  "noopener noreferrer"
                                );
                              }
                            }}
                            className={"btn btn-circle btn-xs"}
                            disabled={!estab?.location}
                          >
                            <i className="fa-solid fa-location-dot"></i>
                          </button>

                          {userClientEstabs.some(
                            (e) => e._id === estab._id
                          ) && (
                            <ToggleAddEstabUserBtn estabId={estab._id} small />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {estab?.servicos?.map((s: any, i: any) => (
              <div key={i} className="stats shadow" style={{ display: "flow" }}>
                <table className="table">
                  <tbody>
                    <tr style={{ border: 0 }}>
                      <td className="w-full">
                        <div className="flex items-center gap-3">
                          <div>
                            <div className="text-2xl">{s.name}</div>
                            <div className="text-sm opacity-50">
                              {s.description}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="w-auto">
                        <div className="text-xs">Valor</div>
                        <div>
                          <span className="mr-1">R$</span>
                          {formatToReal(s.price)}
                        </div>
                      </td>

                      <td className="w-auto">
                        <div className="text-xs">Duração</div>
                        <DurationService duration={s.duration} />
                      </td>

                      {userClientEstabs.some((e) => e._id === estabId) && (
                        <td className="w-auto">
                          <div className="flex items-center">
                            <button className="btn btn-warning btn-sm">
                              Agendar
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>

                <div className="flex px-4 pb-2" style={{ border: 0 }}>
                  {s?.imagesIds?.map((fileId: string) => (
                    <FilePreviewImage key={fileId} fileId={fileId} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
