import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useState } from "react";
import { getInitialName } from "../../../components/utils";

export default function Avatar() {
  const navigate = useNavigate();
  const { logout, user } = useAuthContext();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="dropdown dropdown-end">
      <div
        tabIndex={0}
        className="avatar cursor-pointer placeholder"
        onClick={() => {
          setDropdownOpen(true);
        }}
      >
        <div className="w-11 rounded-full ring ring-orange-50 bg-neutral">
          {!!user?.photoURL ? (
            <img src={user?.photoURL} alt="User Avatar" />
          ) : (
            <div className="rounded-full text-neutral-content">
              <span className="text-2xl">
                {getInitialName(user?.displayName || user?.email || "")}
              </span>
            </div>
          )}
        </div>
      </div>
      <ul
        tabIndex={0}
        style={{ display: dropdownOpen ? "inherit" : "none" }}
        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
      >
        <li>
          <a
            href="#w"
            onClick={() => {
              navigate("/configuracoes/usuario");
              setDropdownOpen(false);
            }}
          >
            Conta
          </a>
        </li>
        {(user?.adminSystem || user?.role === "organization-admin") && (
            <li>
              <a
                onClick={() => {
                  navigate("/configuracoes/estabelecimento");
                  setDropdownOpen(false);
                }}
              >
                Estabelecimento
              </a>
            </li>
          )}
        <li>
          <a
            onClick={() => {
              logout();
              setDropdownOpen(false);
            }}
          >
            Sair
          </a>
        </li>
      </ul>
    </div>
  );
}
