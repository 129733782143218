import { useEffect, useState } from "react";
import MultiSelect from "./MultiSelect";
import { AtendentesService } from "../../modules/atendentes/Service";
const service = new AtendentesService();

export default function SelectAtendentes({
  name,
  value,
  setValue,
}: {
  name: string;
  value: string[];
  setValue: (value: string[]) => void;
}) {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  useEffect(() => {
    service.getAll().then((response) => {
      const atendentes: any = response?.data?.data || [];

      setOptions(
        atendentes.map((e: any) => ({ value: e.uid, label: e.displayName }))
      );

      setLoading(false);
    });
  }, []);

  return (
    <MultiSelect
      options={options}
      loading={loading}
      value={value}
      onChange={setValue}
      placeholder="Selecione os atendentes"
      label="Atendentes"
      name={name}
    />
  );
}
