import { useAppContext } from "../../../contexts/AppContext";
import { useEstabsContext } from "../../../contexts/EstabsContext";
import Avatar from "./Avatar";
import Notifications from "../../../modules/notifications/Notifications";
import { urlFile } from "../../../AxiosConfig";

export default function Header() {
  const { estabSession } = useEstabsContext();
  const { toggleShortMenu, currentPath, isScrolled } = useAppContext();

  return (
    <div
      className={`flex justify-between items-center py-2 backdrop-blur-lg bg-white bg-opacity-75 px-3 ${
        isScrolled
          ? "shadow-[0_15px_15px_-14px_rgba(0,0,0,0.3)]"
          : "border-b border-gray-50"
      }`}
    >
      <div className="flex items-center">
        <button className="btn btn-circle" onClick={toggleShortMenu}>
          <i className="fa-solid fa-bars"></i>
        </button>

        <div
          className={`ml-20 absolute font-medium transition-opacity duration-300 ${
            isScrolled ? "opacity-100" : "opacity-0"
          }`}
        >
          {currentPath?.title}
        </div>
      </div>

      <div className="avatar">
        {estabSession?.imageId ? (
        <div className="w-56 h-12 rounded border border-gray-200 text-center content-center relative">

          <img
            src={`${urlFile}/${estabSession?.imageId}`}
            alt="file preview"
            className="w-full h-full object-cover"
          />
        </div>
        ) : (
          <span>{estabSession?.name}</span>
        )}
      </div>

      <div className="flex justify-center">
        <Notifications />
        <Avatar />
      </div>
    </div>
  );
}
