import { Route } from "react-router-dom";
import Agenda from "./modules/agenda/estabelecimento/Agenda";
import Content from "./pages/dashboard/content/Content";
import PageNotFound from "./components/PageNotFound";
import Servicos from "./modules/servicos/Servicos";
import Estabelecimento from "./modules/configuracoes/estabelecimento/Estabelecimento";
import Clientes from "./modules/clientes/Clientes";
import Estabelecimentos from "./modules/estabelecimentos/system-admin/Estabelecimentos";
import Usuarios from "./modules/usuarios/Usuarios";
import ContentActions from "./pages/dashboard/content/Actions";
import ConfiguracoesConta from "./modules/configuracoes/usuario/Configuracoes";
import UsuariosSistema from "./modules/usuarios-sistema/UsuariosSistema";
import { UsersProvider } from "./modules/usuarios-sistema/Context";
import { EstabsProvider } from "./modules/estabelecimentos/system-admin/Context";
import { ServicosProvider } from "./modules/servicos/Context";
import NotificationsAll from "./modules/notifications/NotificationsAll";
import Atendentes from "./modules/atendentes/Atendentes";
import { AtendentesProvider } from "./modules/atendentes/Context";
import { AgendaProvider } from "./modules/agenda/estabelecimento/ContextAgenda";
import AgendaCliente from "./modules/agenda/cliente/Agenda";
import { AgendaClienteProvider } from "./modules/agenda/cliente/Context";
import EstabelecimentosClient from "./modules/estabelecimentos/client/Estabelecimentos";
import { EstabsClientProvider } from "./modules/estabelecimentos/client/Context";

// Configurações dos menus
const menus = (user: any) =>
  [
    {
      title: "Agenda",
      path: "/",
      role: [
        "system-admin",
        "organization-admin",
        "organization-worker",
        "client",
      ],
      icon: "fa-solid fa-house",
    },
    {
      title: "Serviços",
      path: "/servicos",
      role: ["system-admin", "organization-admin"],
      icon: "fa-solid fa-briefcase",
    },
    {
      title: "Clientes",
      path: "/clientes",
      role: ["system-admin", "organization-admin", "organization-worker"],
      icon: "fa-solid fa-address-card",
    },
    {
      title: "Atendentes",
      path: "/atendentes",
      role: ["system-admin", "organization-admin"],
      icon: "fa-solid fa-users",
    },
    {
      title: "Estabelecimentos",
      path: "/estabelecimentos",
      role: ["system-admin"],
      icon: "fa-solid fa-store",
    },
    {
      title: "Usuários Sistema",
      path: "/usuario-sistema",
      role: ["system-admin"],
      icon: "fas fa-users-cog",
    },
    {
      title: "Estabelecimentos",
      path: "/estabelecimentos",
      role: ["client"],
      icon: "fa-solid fa-store",
    },
    // {
    //   title: "Usuários",
    //   path: "/usuarios",
    //   icon: "fas fa-user-circle",
    // },
  ].filter(
    (e) =>
      !e?.role?.length || e?.role?.includes(user.role) || !!user.adminSystem
  );

// Configurações das rotas
const reutesConfig = [
  {
    path: "/",
    title: "Agenda",
    role: ["system-admin", "organization-admin", "organization-worker"],
    Element: () => (
      <AgendaProvider>
        <Agenda />
      </AgendaProvider>
    ),
    Actions: () => <ContentActions actions={[{ type: "settings" }]} />,
  },
  {
    path: "/agenda",
    title: "Agenda",
    role: ["system-admin", "organization-admin", "organization-worker"],
    Element: () => (
      <AgendaProvider>
        <Agenda />
      </AgendaProvider>
    ),
    Actions: () => <ContentActions actions={[{ type: "add" }]} />,
  },
  {
    path: "/",
    title: "Agenda",
    role: ["client"],
    Element: () => (
      <AgendaClienteProvider>
        <AgendaCliente />
      </AgendaClienteProvider>
    ),
  },
  {
    path: "/agenda",
    title: "Agenda",
    role: ["client"],
    Element: () => (
      <AgendaClienteProvider>
        <AgendaCliente />
      </AgendaClienteProvider>
    ),
  },
  {
    path: "/servicos",
    title: "Serviços",
    role: ["system-admin", "organization-admin"],
    Element: () => (
      <ServicosProvider>
        <Servicos />
      </ServicosProvider>
    ),
    Actions: () => <ContentActions actions={[{ type: "add" }]} />,
  },
  {
    path: "/atendentes",
    title: "Atendentes",
    role: ["system-admin", "organization-admin"],
    Element: () => (
      <AtendentesProvider>
        <Atendentes />
      </AtendentesProvider>
    ),
    Actions: () => <ContentActions actions={[{ type: "add" }]} />,
  },
  {
    path: "/clientes",
    title: "Clientes",
    role: ["system-admin", "organization-admin", "organization-worker"],
    Element: Clientes,
  },
  {
    path: "/estabelecimentos",
    title: "Estabelecimentos",
    role: ["system-admin"],
    Element: () => (
      <EstabsProvider>
        <Estabelecimentos />
      </EstabsProvider>
    ),
    Actions: () => <ContentActions actions={[{ type: "add" }]} />,
  },
  {
    path: "/estabelecimentos",
    title: "Meus Estabelecimentos",
    role: ["client"],
    Element: () => (
      <EstabsClientProvider>
        <EstabelecimentosClient />
      </EstabsClientProvider>
    ),
    Actions: () => <ContentActions actions={[{ type: "add" }]} />,
  },
  {
    path: "/usuarios",
    title: "Usuários",
    role: ["system-admin"],
    Element: Usuarios,
  },
  {
    path: "/usuario-sistema",
    title: "Usuários sistema",
    role: ["system-admin"],
    Element: () => (
      <UsersProvider>
        <UsuariosSistema />
      </UsersProvider>
    ),
    Actions: () => <ContentActions actions={[{ type: "add" }]} />,
  },
  {
    path: "/configuracoes/usuario",
    title: "Configurações da Conta",
    Element: ConfiguracoesConta,
    returnHome: true,
  },
  {
    path: "/configuracoes/estabelecimento",
    title: "Estabelecimento",
    rule: ["system-admin", "organization-admin"],
    Element: Estabelecimento,
    returnHome: true,
  },
  {
    path: "/notificacoes",
    title: "Notificações",
    Element: NotificationsAll,
    returnHome: true,
  },
];

const routes = (user: any) => [
  ...reutesConfig
    .filter(
      (e) =>
        !e?.role?.length || e?.role?.includes(user.role) || !!user.adminSystem
    )
    .map(({ path, Element, title, Actions, returnHome }, i) => (
      <Route
        key={i}
        path={path}
        element={
          <Content
            title={title}
            Element={Element}
            Actions={Actions}
            returnHome={returnHome}
          />
        }
      />
    )),
  <Route key="not-found" path="*" element={<PageNotFound />} />,
];

export { menus, routes, reutesConfig };
