import React, { createContext, useContext, ReactNode } from 'react';

interface ContentContextProps {
}

const ContentContext = createContext<ContentContextProps | undefined>(undefined);

const ContentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  return (
    <ContentContext.Provider value={{  }}>
      {children}
    </ContentContext.Provider>
  );
};

const useContentContext = () => {
  const context = useContext(ContentContext);
  if (context === undefined) {
    throw new Error('useContentContext must be used within an ContentProvider');
  }
  return context;
};

export { ContentProvider, useContentContext };
