import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { UserService } from "../../modules/configuracoes/usuario/Service";
import { useAuthContext } from "../../contexts/AuthContext";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
const service = new UserService();

export default function VerificationAccount() {
  const navigate = useNavigate();
  const { errorSnackbar, successSnackbar } = useAppContext();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [uid, setUid] = useState("");

  useEffect(() => {
    const uid = searchParams.get("uid");
    const codeParam = searchParams.get("code");

    if (uid && codeParam) {
      setUid(uid);
      setCode(codeParam);
    } else {
      navigate("/");
    }
  }, [searchParams]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      errorSnackbar("As senhas não correspondem.");
      return;
    }

    setLoading(true);
    try {
      const response = await service.activeAccount(uid, code, password);

      successSnackbar("Usuário ativado com sucesso!");
      await signInWithEmailAndPassword(auth, response?.data?.email, password);
      navigate("/");
    } catch (error) {
      console.error(error);
      errorSnackbar("Ocorreu um erro ao ativar o usuário.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center h-full">
      <div className="mx-auto w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <img src="logo.png" alt="Logo" className="h-10 mx-auto mt-2" />
        <div className="text-lg px-2 pt-6 text-center">
          Bem Vindo(a) ao Salon Flow, crie uma senha e confirme sua conta!
        </div>
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Senha
              </label>
              <input
                type="password"
                name="password"
                id="password"
                disabled={loading}
                placeholder="••••••••"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Confirmar Senha
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                disabled={loading}
                placeholder="••••••••"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full h-10 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {loading ? (
                <span className="loading loading-bars loading-sm" />
              ) : (
                "Confirmar"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
