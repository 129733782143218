import { useEffect } from "react";
import { createPortal } from "react-dom";
import { urlFile } from "../../AxiosConfig";

interface FileModalProps {
  fileId: string;
  onClose: () => void;
}

export const FileModal = ({ fileId, onClose }: FileModalProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return createPortal(
    <div
      onClick={handleClickOutside}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        padding: "20px 20px",
      }}
    >
      <div className="p-3 bg-slate-50 relative flex rounded-lg shadow-2xl">
        <button
          className="btn btn-xs btn-circle btn-neutral hover:opacity-80 absolute right-1 top-1"
          onClick={onClose}
        >
          ✕
        </button>
        <img src={`${urlFile}/${fileId}`} alt="file preview" className="" />
      </div>
    </div>,
    document.getElementById("outside-root") as HTMLElement
  );
};
