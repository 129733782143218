import TableActions from "../../components/table/Actions";
import CustomDataTable from "../../components/table/Table";

interface DataRow {
  id: number;
  nome: string;
  email: string;
}

const data: DataRow[] = [
  {
    id: 1,
    nome: "Otávio Hemckmeier",
    email: "otavio.hemck@gmail.com",
  },
  {
    id: 1,
    nome: "Otávio Hemckmeier",
    email: "otavio.hemck@gmail.com",
  },
  {
    id: 1,
    nome: "Otávio Hemckmeier",
    email: "otavio.hemck@gmail.com",
  },
  {
    id: 1,
    nome: "Otávio Hemckmeier",
    email: "otavio.hemck@gmail.com",
  },
  {
    id: 1,
    nome: "Otávio Hemckmeier",
    email: "otavio.hemck@gmail.com",
  },
];

const columns = [
  {
    name: "Nome",
    selector: (row: DataRow) => row.nome,
    sortable: true,
    cell: (row: DataRow) => (
      <>
        <div className="avatar mr-2">
          <div className="w-10 rounded-full">
            <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
          </div>
        </div>
        <div style={{ textAlign: "right" }}>{row.nome}</div>
      </>
    ),
  },
  {
    name: "Email",
    selector: (row: DataRow) => row.email,
    sortable: true,
    cell: (row: DataRow) => (
      <div style={{ textAlign: "right" }}>{row.email}</div>
    ),
  },
  {
    name: "Ações",
    right: true,
    sortable: false,
    cell: (row: DataRow) => {
      return (
        <>
          <div className="dropdown dropdown-left mr-1">
            <button className="btn btn-outline btn-success btn-sm">
              Ativo
            </button>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 bg-base-100 rounded-box w-52 shadow-lg"
            >
              <li>
                <a>Ativar</a>
              </li>
              <li>
                <a>Desativar</a>
              </li>
            </ul>
          </div>
          <TableActions
            row={row}
            options={[
              {
                type: "edit",
              },
            ]}
          ></TableActions>
        </>
      );
    },
  },
];

export default function UsuariosList() {
  return (
    <CustomDataTable
      columns={columns}
      data={data}
      pagination
      highlightOnHover
      striped
    />
  );
}
