import { EstabelecimentoForm } from "./Form/Form";
import { EstabFormProvider } from "./Form/Context";

export default function Estabelecimento() {
  return (
    <EstabFormProvider>
      <EstabelecimentoForm />
    </EstabFormProvider>
  );
}
