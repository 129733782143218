import { useState } from "react";
import { EstabelecimentosService } from "../Service";
import Dropdown from "../../../../components/Dropdown";
import { useAppContext } from "../../../../contexts/AppContext";
import { useEstabsContext } from "../Context";

const service = new EstabelecimentosService();

interface StatusProps {
  id: string;
  status: number;
}

export default function EstabelecimentosListStatus({
  id,
  status,
}: StatusProps) {
  const { errorSnackbar } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { reloadData } = useEstabsContext();

  const update = async (status: number) => {
    try {
      setLoading(true);
      await service.update(id, { status });
      reloadData();
    } catch (e) {
      console.log(e);
      
      errorSnackbar(
        `Ocorreu um erro ao ${
          status === 1 ? "ativar" : "desativar"
        } o estabelecimento.`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dropdown
      label={status === 1 ? "Ativo" : "Desativado"}
      isDisabled={loading}
      buttonClass={status === 2 ? "btn-error" : "btn-success"}
      actions={[
        status === 2
          ? {
              label: "Ativar",
              action: async () => {
                update(1);
              },
            }
          : {
              label: "Desativar",
              action: async () => {
                update(2);
              },
            },
      ]}
    />
  );
}
