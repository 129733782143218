import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { EstabelecimentosService } from "../modules/estabelecimentos/system-admin/Service";
import { useAuthContext } from "./AuthContext";
import { UserService } from "../modules/configuracoes/usuario/Service";
import { useAppContext } from "./AppContext";
const estabsService = new EstabelecimentosService();
const userService = new UserService();

interface EstabsContextProps {
  estabsUser: any[];
  userClientEstabs: any[];
  estabSession: any;
  switchEstab: (estabId: string) => void;
  reloadEstab: () => void;
}

const EstabsContext = createContext<EstabsContextProps | undefined>(undefined);

const EstabsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuthContext();
  const { errorSnackbar } = useAppContext();
  const [estabsUser, setEstabsUser] = useState<string[]>([]);
  const [userClientEstabs, setUserClientEstabs] = useState([]);
  const [estabSession, setEstabSession] = useState<{}>({});

  const reloadEstab = () => {
    if (!!user) {
      estabsService.findAll().then(async (response) => {
        setEstabsUser(response?.data);

        const estab = response?.data?.find(
          (e: any) => e._id === user?.estabIdSession
        );

        setEstabSession(estab);
      });

      userService.getUserClientEstabs().then(async (response) => {
        setUserClientEstabs(response?.data);
      });
    }
  };

  useEffect(() => {
    reloadEstab();
  }, [user]);

  const switchEstab = (estabId: string) => {
    if (user?.estabIdSession !== estabId) {
      userService
        .switchEstab(estabId)
        .then((_) => {
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);

          errorSnackbar("Ocorreu um erro ao trocar de estabelecimento.");
        });
    }
  };

  return (
    <EstabsContext.Provider
      value={{
        switchEstab,
        estabsUser,
        estabSession,
        reloadEstab,
        userClientEstabs,
      }}
    >
      {children}
    </EstabsContext.Provider>
  );
};

const useEstabsContext = () => {
  const context = useContext(EstabsContext);
  if (context === undefined) {
    throw new Error("useEstabsContext must be used within an EstabsProvider");
  }
  return context;
};

export { EstabsProvider, useEstabsContext };
