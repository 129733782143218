import { io, Socket } from "socket.io-client";
import { baseURL } from "./AxiosConfig";

class WebSocketManager {
  socket: Socket;

  constructor() {
    this.socket = io(baseURL, {
      auth: {
        token: localStorage.getItem("authToken"),
      },
    });
  }

  onChannel(channel: string, callback: (message: any) => void) {
    if (this.socket) {
      this.socket.on(channel, callback);
    }
  }

  sendMessage(channel: string, message: any) {
    if (this.socket) {
      this.socket.emit(channel, message);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default WebSocketManager;
