import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { AtendentesService } from "./Service";
const service = new AtendentesService();

interface Atendentes {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  photoURL: string;
  phoneNumber: string;
  disabled: boolean;
  metadata: any;
}

interface AtendentesContextProps {
  atendentes: Atendentes[];
  loading: boolean;
  setAtendentes: React.Dispatch<React.SetStateAction<Atendentes[]>>;
  fetchAtendentes: () => Promise<void>;
}

const AtendentesContext = createContext<AtendentesContextProps | undefined>(
  undefined
);

export const useAtendentesContext = () => {
  const context = useContext(AtendentesContext);
  if (!context) {
    throw new Error(
      "useAtendentesContext must be used within a AtendentesProvider"
    );
  }
  return context;
};

export const AtendentesProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [atendentes, setAtendentes] = useState<Atendentes[]>([]);

  const fetchAtendentes = async () => {
    try {
      const result = await service.getAll().then((result) => {
        return result?.data?.data || [];
      });
      setAtendentes(result);
    } catch (error) {
      console.error("Error fetching atendentes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAtendentes();
  }, []);

  return (
    <AtendentesContext.Provider
      value={{ atendentes, setAtendentes, fetchAtendentes, loading }}
    >
      {children}
    </AtendentesContext.Provider>
  );
};
