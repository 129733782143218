import { useNotificationsContext } from "./NotificationsContext";
import NotificationItem from "./Item";
import { useNavigate } from "react-router-dom";
import Dropdown from "../agenda/estabelecimento/components/Dropdown";
import { NotificationsService } from "./Service";
import { useState } from "react";
const service = new NotificationsService();

export default function Notifications() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    notifications,
    countNotVisualized,
    dropdownOpen,
    setDropdownOpen,
    onlyNotVisualized,
    setOnlyNotVisualized,
    loadNotifications,
  } = useNotificationsContext();

  return (
    <div className="flex self-center mr-8">
      <div className="dropdown dropdown-end dropdown-bottom">
        <div className="indicator" tabIndex={0} role="button">
          {countNotVisualized > 0 && (
            <span className="indicator-item badge badge-secondary text-xs">
              {countNotVisualized > 9 ? "9+" : countNotVisualized}
            </span>
          )}
          <button
            className="btn btn-circle btn-sm"
            onClick={() => {
              setDropdownOpen(true);
            }}
          >
            <i className="fa-regular fa-bell"></i>
          </button>
        </div>
        <div
          tabIndex={0}
          className="dropdown-content card bg-base-100 text-base-content z-[1] w-96 shadow-2xl"
          style={{ display: dropdownOpen ? "inherit" : "none" }}
        >
          <div
            className="card-body overflow-y-auto"
            style={{ maxHeight: "550px", padding: "15px" }}
          >
            <div className="flex justify-between mb-2 items-center">
              <h3 className="card-title text-lg font-semibold">Notificações</h3>

              <Dropdown
                options={[
                  {
                    label: "Ler todas",
                    onClick: async () => {
                      setLoading(true);
                      try {
                        await service.viewAll();
                        loadNotifications();
                      } catch (e) {
                      } finally {
                        setLoading(false);
                      }
                    },
                  },
                  {
                    label: "Remover todas",
                    onClick: async () => {
                      setLoading(true);
                      try {
                        await service.removeAll();
                        loadNotifications();
                      } catch (e) {
                      } finally {
                        setLoading(false);
                      }
                    },
                  },
                ]}
              >
                <button
                  disabled={loading}
                  className={`btn btn-sm opacity-80 w-full actionStatus`}
                >
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </button>
              </Dropdown>
            </div>

            <div className="flex items-center justify-between">
              <span className="text-base font-semibold">Recentes</span>
              <button
                className="btn btn-ghost btn-sm"
                onClick={() => {
                  navigate("notificacoes");
                  setDropdownOpen(false);
                }}
              >
                Ver tudo
              </button>
            </div>

            <div className="divider" style={{ padding: 0, margin: 0 }}></div>

            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Apenas não lidas</span>
                <input
                  type="checkbox"
                  className="toggle toggle-sm"
                  defaultChecked={onlyNotVisualized}
                  onClick={() => setOnlyNotVisualized(!onlyNotVisualized)}
                />
              </label>
            </div>

            {notifications.length === 0 ? (
              <p className="text-sm text-gray-500">Nenhuma notificação</p>
            ) : (
              <ul
                className="menu menu-lg"
                style={{
                  padding: 0,
                }}
              >
                {notifications?.map((notification, index) => (
                  <NotificationItem key={index} notification={notification} />
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
