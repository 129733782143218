import moment from "moment";
import "moment/locale/pt-br";
import { useAgendaContext } from "../ContextAgenda";
import { useParamsContext } from "../../../../contexts/ParamsContext";
import ScheduleViewHeader from "./ScheduleViewHeader";
import { getInitialName } from "../../../../components/utils";
import ScheduleHistory from "./ScheduleHistory";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../../contexts/AppContext";

moment.locale("pt-br");

export default function ScheduleView() {

  const {shortMenu} = useAppContext()

  const { clear } = useParamsContext();
  const { selectedSchedule: schedule } = useAgendaContext();

  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [fixed, setFixed] = useState(false);

  const updateWidth = () => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [shortMenu]);

  useEffect(() => {
    const handleScroll = () => {
      setFixed(window.scrollY > 179);
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="" ref={divRef}>
      <div
        className={`${fixed && "fixed top-20"} `}
        style={{ width: `${width}px` }}
      >
        <div className="flex justify-end gap-3 mb-2">
          <button className="btn btn-circle btn-sm" onClick={clear}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>

        {schedule && <ScheduleViewHeader schedule={schedule} />}

        <div role="tablist" className="tabs tabs-bordered mt-3">
          <input
            type="radio"
            name="my_tabs_1"
            role="tab"
            className="tab"
            aria-label="Geral"
            defaultChecked
          />
          <div role="tabpanel" className="tab-content">
            <div className="overflow-x-auto">
              <table className="table">
                <tbody>
                  <tr className="hover">
                    <th>Atendente</th>
                    <td>
                      <div className="flex items-center gap-3">
                        <div className="avatar">
                          <div className="bg-neutral text-neutral-content mask mask-squircle h-12 w-12 content-center text-center">
                            {schedule?.attendant.profileImageUrl ? (
                              <img
                                alt="Foto do Cliente"
                                src={schedule.attendant.profileImageUrl}
                              />
                            ) : (
                              <span className="text-xl">
                                {getInitialName(schedule?.attendant.name)}
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="font-bold">
                            {schedule?.attendant.name}
                          </div>
                          <button
                            onClick={() => {
                              window.open(
                                schedule?.attendant.whatsappLink,
                                "post"
                              );
                            }}
                            disabled={!schedule?.attendant?.whatsappLink}
                            className="btn btn-circle btn-xs"
                          >
                            <i className="fa-brands fa-whatsapp"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr className="hover">
                    <th>Avaliação</th>
                    <td>
                      {!!schedule?.assessment && (
                        <div className="grid">
                          <div className="rating">
                            {[...Array(5)].map((_, index) => (
                              <input
                                key={index}
                                type="radio"
                                name="rating-2"
                                className={`mask mask-star-2 bg-orange-400 ${
                                  schedule.assessment &&
                                  index < schedule.assessment.value
                                    ? "checked"
                                    : ""
                                }`}
                                defaultChecked={
                                  schedule.assessment &&
                                  index === schedule.assessment.value - 1
                                }
                                disabled
                              />
                            ))}
                          </div>

                          <div>{schedule.assessment.observation || ""}</div>

                          <div className="text-xs">
                            {moment(schedule?.assessment?.date).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>

                  <tr className="hover">
                    <th>Motivo cancelamento</th>
                    <td>
                      <div className="">
                        <div>{schedule?.cancellation?.observation}</div>
                        <div className="text-xs">
                          {moment(schedule?.cancellation?.date).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr className="hover">
                    <th>Assessibilibade</th>
                    <td>{schedule?.accessibility?.observation}</td>
                  </tr>

                  <tr className="hover">
                    <th>Observação</th>
                    <td>{schedule?.observation}</td>
                  </tr>

                  <tr className="hover">
                    <th>Agendado em</th>
                    <td>
                      {moment(schedule?.createdAt).format("DD/MM/YYYY HH:mm")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <input
            type="radio"
            name="my_tabs_1"
            role="tab"
            className="tab"
            aria-label="Histórico"
          />
          <div role="tabpanel" className="tab-content pt-5">
            {schedule?.id && <ScheduleHistory id={schedule.id} />}
          </div>
        </div>
      </div>
    </div>
  );
}
