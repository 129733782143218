import { columns } from "./Columns";
import { useEstabsContext } from "../Context";
import CustomDataTable from "../../../../components/table/Table";

export default function EstabelecimentosList() {
  const { data, loading } = useEstabsContext();
  
  return (
    <CustomDataTable
      columns={columns}
      data={data}
      loading={loading}
      pagination
      highlightOnHover
      striped
    />
  );
}
