import TableActions from "../../../components/table/Actions";

export function ActionsService({ row }: any) {
  return (
    <TableActions
      row={row}
      options={[
        {
          type: "edit",
        },
        {
          type: "delete",
        },
      ]}
    />
  );
}
