import CustomDataTable from "../../components/table/Table";
import { UsuariosSistemaService } from "./Service";
import AvatarUser from "../../components/AvatarUser";
import { useUsersContext } from "./Context";
import Dropdown from "../../components/Dropdown";
import { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import TableActions from "../../components/table/Actions";
const service = new UsuariosSistemaService();

interface DataRow {
  uid: string;
  displayName: string;
  email: string;
  photoURL: string;
  disabled: boolean;
  emailVerified: boolean;
}

export default function UsuariosSistemaList() {
  const { users, loading } = useUsersContext();

  const columns = [
    {
      name: "Nome",
      selector: (row: DataRow) => row.displayName,
      sortable: true,
      cell: (row: DataRow) => (
        <>
          <AvatarUser
            photoURL={row.photoURL}
            name={row.displayName || row.email}
          />
          <div style={{ textAlign: "right", marginLeft: "10px" }}>{row.displayName}</div>
        </>
      ),
    },
    {
      name: "Email",
      selector: (row: DataRow) => row.email,
      sortable: true,
      cell: (row: DataRow) => (
        <div className="flex items-center">
          {row.emailVerified ? (
            <div
              className="tooltip fixed tooltip-bottom"
              data-tip={"Email verificado"}
            >
              <i className="fas fa-check-circle mr-1 text-green-600" />
            </div>
          ) : (
            <div
              className="tooltip fixed tooltip-bottom"
              data-tip={"Email não verificado"}
            >
              <i className="fas fa-times-circle mr-1 text-red-600" />
            </div>
          )}

          <div className="ml-4">{row.email}</div>
        </div>
      ),
    },
    {
      name: "Ações",
      right: true,
      sortable: false,
      cell: (row: DataRow) => (
        <>
          <ActionList row={row} />
          <TableActions
            row={row}
            options={[
              {
                type: "delete",
              },
            ]}
          />
        </>
      ),
    },
  ];

  return (
    <CustomDataTable
      columns={columns}
      data={users}
      loading={loading}
      pagination
      highlightOnHover
      striped
    />
  );
}

const ActionList = ({ row }: any) => {
  const { fetchUsers } = useUsersContext();
  const [loading, setLoading] = useState(false);
  const { errorSnackbar } = useAppContext();

  return (
    <Dropdown
      label={row.disabled ? "Desativado" : "Ativo"}
      isDisabled={loading}
      buttonClass={row.disabled ? "btn-error" : "btn-success"}
      actions={[
        row.disabled
          ? {
              label: "Ativar",
              action: async () => {
                setLoading(true);

                try {
                  await service.active(row.uid);
                  fetchUsers();
                } catch (e) {
                  errorSnackbar("Ocorreu um erro ao alterar a situação");
                } finally {
                  setLoading(false);
                }
              },
            }
          : {
              label: "Desativar",
              action: async () => {
                setLoading(true);

                try {
                  await service.disable(row.uid);
                  fetchUsers();
                } catch (e) {
                  errorSnackbar("Ocorreu um erro ao alterar a situação");
                } finally {
                  setLoading(false);
                }
              },
            },
      ]}
    />
  );
};
