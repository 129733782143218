import { useState, Dispatch, SetStateAction } from "react";
import { UserService } from "../../modules/configuracoes/usuario/Service";
import { useAppContext } from "../../contexts/AppContext";
const service = new UserService();

interface CreateAccountProps {
  setCreateAccount: Dispatch<SetStateAction<boolean>>;
}

export default function CreateAccount({
  setCreateAccount,
}: CreateAccountProps) {
  const { errorSnackbar, successSnackbar } = useAppContext();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmitAccount = async () => {
    try {
      setLoading(true);
      await service.create({ name, email });
      successSnackbar("Acesse seu email para confirmar sua conta!");
      setCreateAccount(false);
    } catch (error: any) {
      errorSnackbar(
        error?.response?.data?.message || "Ocorreu um erro ao criar a conta."
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form
        className="space-y-4 md:space-y-6"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitAccount();
        }}
      >
        <div>
          <label
            htmlFor="name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Nome
          </label>
          <input
            type="text"
            name="name"
            disabled={loading}
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Seu nome"
            required
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            disabled={loading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="name@company.com"
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full h-10 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-black text-white"
        >
          {loading ? (
            <span className="loading loading-bars loading-sm" />
          ) : (
            "Continuar"
          )}
        </button>
      </form>

      <div className="flex items-center justify-center mt-4">
        <a
          href="#"
          className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
          onClick={() => setCreateAccount(false)}
        >
          Cancelar
        </a>
      </div>
    </div>
  );
}
