import React, {
  useState,
  useEffect,
  ChangeEvent,
  ReactNode,
  useRef,
} from "react";
import { getAuth } from "firebase/auth";
import { FileService } from "./service";
import { convertFileToBase64 } from "./functions";
import { fi } from "date-fns/locale";
import { urlFile } from "../../AxiosConfig";

const service = new FileService();

interface FileUploadProps {
  value: string;
  setValue: (url: string) => void;
  children?: (props: {
    loading: boolean;
    fileData: any;
    removeFile: any;
    error: string | null;
    inputRef: React.RefObject<HTMLInputElement>;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  }) => ReactNode;
}

export default function FileUploadUser({
  value,
  setValue,
  children,
}: FileUploadProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (value) {
      setFileData({ url: value });
    }
  }, [value, inputRef]);

  const removeFile = () => {
    setValue("");
    setFileData(null);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleUpload(e.target.files[0]);
    }
  };

  const handleUpload = async (file: any) => {
    try {
      setLoading(true);
      if (file) {
        setError(null);
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          setError("Usuário não autenticado");
          return;
        }
        const base64Content = await convertFileToBase64(file);

        const response = await service.upload(
          base64Content,
          file.name,
          file.size,
          file.type,
          file?.lastModifiedDate,
          "profile"
        );

        setValue(`${urlFile}/${response.data._id}`);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {children &&
        children({
          loading,
          fileData,
          error,
          handleChange,
          removeFile,
          inputRef,
        })}
    </div>
  );
}
