import React, { createContext, useContext, useState, useEffect } from "react";
import WebSocketManager from "../../WebSocketConfig";
import { NotificationsService } from "./Service";
import { useAuthContext } from "../../contexts/AuthContext";
const service = new NotificationsService();
interface Notification {
  id: string;
  title: string;
  message: string;
  path?: string;
  createdAt: Date;
  type?: string;
  visualizedAt?: Date;
}

interface NotificationsContextProps {
  notifications: Notification[];
  dropdownOpen: boolean;
  setDropdownOpen: (val: boolean) => void;
  onlyNotVisualized: boolean;
  setOnlyNotVisualized: (val: boolean) => void;
  countNotVisualized: number;
  loadNotifications: () => void;
}

const NotificationsContext = createContext<
  NotificationsContextProps | undefined
>(undefined);

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      "useNotificationsContext must be used within a NotificationsProvider"
    );
  }
  return context;
};

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuthContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [onlyNotVisualized, setOnlyNotVisualized] = useState(
    localStorage.getItem("onlyNotVisualized") === "true"
  );
  const [data, setData] = useState<Notification[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [countNotVisualized, setCountNotVisualized] = useState<number>(0);

  const loadNotifications = async () => {
    await service.getAll().then((response) => {
      setData(response.data);
    });
  };

  useEffect(() => {
    if (!!user) {
      loadNotifications();
    }
  }, [user]);

  useEffect(() => {
    if (!!onlyNotVisualized) {
      setNotifications(data.filter((e) => !e.visualizedAt));
    } else {
      setNotifications(data);
    }

    setCountNotVisualized(data?.filter((e) => !e.visualizedAt)?.length || 0);
  }, [data, onlyNotVisualized]);

  useEffect(() => {
    const webSocketManager = new WebSocketManager();

    webSocketManager.onChannel("newNotification", () => {
      loadNotifications();
    });

    return () => {
      webSocketManager.disconnect();
    };
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        countNotVisualized,
        dropdownOpen,
        loadNotifications,
        setDropdownOpen,
        onlyNotVisualized,
        setOnlyNotVisualized: (value) => {
          setOnlyNotVisualized(value);
          localStorage.setItem("onlyNotVisualized", value.toString());
        },
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
