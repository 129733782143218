import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useLocation } from "react-router-dom";
import { reutesConfig } from "../routes";
import { useAuthContext } from "./AuthContext";
import { useSnackbar } from "@swat-sccs/react-simple-snackbar";

interface AppContextProps {
  shortMenu: boolean;
  isScrolled: boolean;
  loadingApp: boolean;
  currentPath: any;
  toggleShortMenu: () => void;
  successSnackbar: any;
  errorSnackbar: any;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { loadingAuth } = useAuthContext();
  const [loadingApp, setLoadingApp] = useState(true);

  const [shortMenu, setShortMenu] = useState(
    localStorage.getItem("shortMenu") === "true"
  );
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const [successSnackbar] = useSnackbar({
    style: {
      backgroundColor: "green",
    },
  });

  const [errorSnackbar] = useSnackbar({
    style: {
      backgroundColor: "tomato",
    },
  });

  const toggleShortMenu = () => {
    localStorage.setItem("shortMenu", (!shortMenu).toString());
    setShortMenu(!shortMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setLoadingApp(loadingAuth);
  }, [loadingAuth]);

  return (
    <AppContext.Provider
      value={{
        shortMenu,
        loadingApp,
        isScrolled,
        toggleShortMenu,
        successSnackbar,
        errorSnackbar,
        currentPath: reutesConfig.find((e) => e.path === location.pathname),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export { AppProvider, useAppContext };
