import { useState } from "react";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { UserService } from "../Service";
import { useAppContext } from "../../../../contexts/AppContext";
import moment from "moment";
const service = new UserService();

export default function ConfiguracoesContaEmail() {
  const { errorSnackbar, successSnackbar } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [expiresIn, setExpiresIn] = useState();

  const { user, changeUser } = useAuthContext();
  const [code, setCode] = useState("");
  const [email, setEmail] = useState(user?.email || "");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!!email && email !== user?.email) {
      setLoading(true);
      try {
        const response = await service.requestChangeEmail(email);

        if (!response?.data?.expiresIn) {
          throw new Error("Erro ao gerar token");
        }

        setExpiresIn(response?.data?.expiresIn);
      } catch (e) {
        console.log(e);
        errorSnackbar("Ocorreu um erro ao alterar o email.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmitCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!!code) {
      setLoading(true);
      try {
        await service.changeEmail(code);

        setCode("");
        setExpiresIn(undefined);
        successSnackbar("Email alterado com sucesso!");
        changeUser({ email });

        setTimeout(() => {
          window.location.reload();
        }, 3000)
      } catch (e) {
        console.log(e);
        errorSnackbar("Código inválido.");
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="form-control mb-4 items-center">
        <span className="loading loading-bars loading-sm" />
      </div>
    );
  }

  if (!!expiresIn) {
    return (
      <div className="form-control mb-4">
        <form onSubmit={handleSubmitCode}>
          <label className="label" htmlFor="email">
            <span className="label-text">
              Digite o código enviado para {email}
            </span>
          </label>
          <input
            type="text"
            id="token"
            required
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="input input-bordered"
          />
          <div className="text-xs mt-1">
            O código expira em:{" "}
            {moment(expiresIn).format("DD/MM/YYYY [às] HH:mm")}
          </div>

          <div className="divider" />

          <button className="btn shadow-md mr-2" type="submit">
            Enviar
          </button>

          <button
            onClick={() => {
              setCode("");
              setExpiresIn(undefined);
            }}
            className="btn btn-error shadow-md"
            type="button"
          >
            Cancelar
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="form-control mb-4">
      <form onSubmit={handleSubmit}>
        <label className="label" htmlFor="email">
          <span className="label-text">Email:</span>
        </label>
        <input
          type="email"
          id="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input input-bordered"
        />
        <div className="divider" />

        <button className="btn shadow-md" type="submit">
          Continuar
        </button>
      </form>
    </div>
  );
}
