import React from "react";
import Select, { MultiValue, ActionMeta } from "react-select";

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  options: Option[];
  value: string[];
  onChange: (selected: string[]) => void;
  placeholder: string;
  label: string;
  loading?: boolean;
  disabled?: boolean;
  name?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  label,
  name,
  loading = false,
  disabled = false,
}) => {
  const handleChange = (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    const selectedValues = newValue.map((option) => option.value);
    onChange(selectedValues);
  };

  const selectedOptions = options.filter((option) =>
    value.includes(option.value)
  );

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "#D1D5DB",
      borderRadius: "0.375rem",
      padding: "0.5rem",
      "&:hover": {
        borderColor: "#D1D5DB",
      },
      boxShadow: "none",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#E5E7EB",
      borderRadius: "0.375rem",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "#374151",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "#6B7280",
      "&:hover": {
        backgroundColor: "#F3F4F6",
        color: "#000",
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#9CA3AF",
    }),
  };

  return (
    <div className="form-control">
      <div className="label">
        <span className="label-text">{label}</span>
      </div>
      <Select
        isMulti
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        placeholder={placeholder}
        isDisabled={disabled}
        isClearable={true}
        styles={customStyles}
        isLoading={loading}
        className="basic-multi-select"
        classNamePrefix="select"
        name={name}
        noOptionsMessage={() => "Nenhuma opção disponível"}
      />
    </div>
  );
};

export default MultiSelect;
