import React from "react";
import InputMask from "react-input-mask";
import { useEstabFormContext } from "./Context";

async function fetchAddressByCep(cep: string) {
  const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
  if (!response.ok) {
    throw new Error("Erro ao buscar o endereço.");
  }
  return response.json();
}

export function EnderecoEstabelecimento() {
  const {
    zipCode,
    setZipCode,
    street,
    setStreet,
    number,
    setNumber,
    complement,
    setComplement,
    neighborhood,
    setNeighborhood,
    city,
    setCity,
    state,
    setState,
  } = useEstabFormContext();

  const estadosBrasileiros = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
    "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
    "RS", "RO", "RR", "SC", "SP", "SE", "TO"
  ];

  const handleCepChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCep = e.target.value.replace(/\D/g, "");
    setZipCode(newCep);
    if (newCep.length === 8) {
      try {
        const data = await fetchAddressByCep(newCep);
        setStreet(data.logradouro);
        setNeighborhood(data.bairro);
        setCity(data.localidade);
        setState(data.uf);
      } catch (error) {
        console.error(error);
        // Você pode adicionar uma mensagem de erro aqui se desejar
      }
    }
  };

  return (
    <>
      <div className="form-control mb-4">
        <label className="label" htmlFor="cep">
          <span className="label-text">CEP:</span>
        </label>
        <InputMask
          mask="99999-999"
          value={zipCode}
          onChange={handleCepChange}
          type="text"
          id="cep"
          className="input input-bordered"
          maskChar={null}
        />
      </div>

      <div className="form-control mb-4">
        <label className="label" htmlFor="state">
          <span className="label-text">Estado:</span>
        </label>
        <select
          id="state"
          value={state}
          onChange={(e) => setState(e.target.value)}
          className="select select-bordered"
        >
          <option value="" disabled>
            Selecione o estado
          </option>
          {estadosBrasileiros.map((estado) => (
            <option key={estado} value={estado}>
              {estado}
            </option>
          ))}
        </select>
      </div>

      <div className="form-control mb-4">
        <label className="label" htmlFor="city">
          <span className="label-text">Cidade:</span>
        </label>
        <input
          type="text"
          id="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className="input input-bordered"
        />
      </div>

      <div className="form-control mb-4">
        <label className="label" htmlFor="neighborhood">
          <span className="label-text">Bairro:</span>
        </label>
        <input
          type="text"
          id="neighborhood"
          value={neighborhood}
          onChange={(e) => setNeighborhood(e.target.value)}
          className="input input-bordered"
        />
      </div>

      <div className="form-control mb-4">
        <label className="label" htmlFor="street">
          <span className="label-text">Rua:</span>
        </label>
        <input
          type="text"
          id="street"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          className="input input-bordered"
        />
      </div>

      <div className="form-control mb-4">
        <label className="label" htmlFor="number">
          <span className="label-text">Número:</span>
        </label>
        <input
          type="text"
          id="number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          className="input input-bordered"
        />
      </div>

      <div className="form-control mb-4">
        <label className="label" htmlFor="complement">
          <span className="label-text">Complemento:</span>
        </label>
        <input
          type="text"
          id="complement"
          value={complement}
          onChange={(e) => setComplement(e.target.value)}
          className="input input-bordered"
        />
      </div>
    </>
  );
}
