import { api } from "../../../AxiosConfig";

interface UserProfile {
  displayName: string;
  photoURL: string;
}
class UserService {
  create(data: { email: string; name: string }) {
    return api.post("users/clients/create", data);
  }

  switchEstab(estabId: string) {
    return api.post("users/switch-estab", { estabId });
  }

  activeAccount(uid: string, code: string, password: string) {
    return api.post("users/activeAccount", { uid, code, password });
  }

  updateProfile(update: UserProfile) {
    return api.post("users/updateProfile", update);
  }

  requestChangeEmail(email: string) {
    return api.post("users/requestChangeEmail", { email });
  }

  requestRecoverPassword(email: string) {
    return api.post("users/requestRecoverPassword", { email });
  }

  validRequestRecoverPassword(email: string, code: string) {
    return api.post("users/validRequestRecoverPassword", { email, code });
  }

  recoverPassword(email: string, token: string, password: string) {
    return api.post("users/recoverPassword", { email, token, password });
  }

  changeEmail(code: string) {
    return api.post("users/changeEmail", { code });
  }

  requestChangePhoneNumber(phoneNumber: string) {
    return api.post("users/requestChangePhoneNumber", { phoneNumber });
  }

  changePhoneNumber(code: string) {
    return api.post("users/changePhoneNumber", { code });
  }

  addUserClientEstab(estabId: string) {
    return api.post("users/addUserClientEstab", { estabId });
  }

  removeUserClientEstab(estabId: string) {
    return api.post("users/removeUserClientEstab", { estabId });
  }

  getUserClientEstabs() {
    return api.get("users/getUserClientEstabs");
  }
}

export { UserService };
