import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { EstabelecimentosService } from "./Service";
const service = new EstabelecimentosService();

interface Data {
  _id: string;
  status: string;
  name: boolean;
}

interface EstabsContextProps {
  data: Data[];
  loading: boolean;
  reloadData: () => Promise<void>;
}

const EstabsContext = createContext<EstabsContextProps | undefined>(undefined);

export const useEstabsContext = () => {
  const context = useContext(EstabsContext);
  if (!context) {
    throw new Error("useEstabsContext must be used within a UsersProvider");
  }
  return context;
};

export const EstabsProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);

  const reloadData = async () => {
    try {
      const result = await service.findAll().then((result) => {
        return result?.data || [];
      });
      setData(result);
    } catch (error) {
      console.error("Error fetching Estabelecimentos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reloadData();
  }, []);

  return (
    <EstabsContext.Provider value={{ data, reloadData, loading }}>
      {children}
    </EstabsContext.Provider>
  );
};
