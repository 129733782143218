import React, { createContext, useContext } from "react";

interface EstabsClientParams {}

const EstabsClientContext = createContext<
EstabsClientParams | undefined
>(undefined);

export const useEstabsClientContext = () => {
  const context = useContext(EstabsClientContext);
  if (!context) {
    throw new Error(
      "useEstabelecimentoClientContext must be used within an EstabelecimentoClientContext"
    );
  }
  return context;
};

export const EstabsClientProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <EstabsClientContext.Provider value={{}}>
      {children}
    </EstabsClientContext.Provider>
  );
};
