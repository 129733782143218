import React, { useEffect } from "react";
import { MaskInput } from "maska";

interface PhoneNumberProps {
  placeholder?: string;
  value: string;
  setValue: (value: string) => void;
  label: string;
}

export default function PhoneNumber({
  placeholder,
  value,
  setValue,
  label,
}: PhoneNumberProps) {
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    
    setValue(event.target.value);
  };

  useEffect(() => {
    new MaskInput(".maska");
  }, []);

  return (
    <div className="form-control mb-4">
      <label className="label" htmlFor="phone">
        <span className="label-text">{label}</span>
      </label>
      <input
        id="phone"
        className="input input-bordered maska"
        placeholder={placeholder}
        data-maska="['+55 (##) ####-####', '+55 (##) # ####-####']"
        value={value}
        onBlur={handlePhoneChange}
      />
    </div>
  );
}
