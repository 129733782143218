interface LoadingProps {
  size?: string;
}

export const Loading = ({ size }: LoadingProps) => {
  switch (size) {
    case "loading-xs":
      return <span className="loading loading-spinner"></span>;
    case "loading-sm":
      return <span className="loading loading-spinner"></span>;
    case "loading-lg":
      return <span className="loading loading-spinner"></span>;

    default:
    case "loading-md":
      return <span className="loading loading-spinner"></span>;
  }
};
