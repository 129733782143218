import CustomDataTable from "../../components/table/Table";
import { AtendentesService } from "./Service";
import AvatarUser from "../../components/AvatarUser";
import { useAtendentesContext } from "./Context";
import Dropdown from "../../components/Dropdown";
import { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import TableActions from "../../components/table/Actions";
import { useAuthContext } from "../../contexts/AuthContext";
const service = new AtendentesService();

interface DataRow {
  uid: string;
  displayName: string;
  email: string;
  photoURL: string;
  disabled: boolean;
  role: string;
  emailVerified: boolean;
}

export default function AtendentesList() {
  const { user } = useAuthContext();
  const { atendentes, loading } = useAtendentesContext();

  const columns = [
    {
      name: "Nome",
      selector: (row: DataRow) => row.displayName,
      sortable: true,
      cell: (row: DataRow) => (
        <>
          <AvatarUser
            photoURL={row.photoURL}
            name={row.displayName || row.email}
          />
          <div className="ml-4" style={{ textAlign: "right" }}>{row.displayName}</div>
        </>
      ),
    },
    {
      name: "Email",
      selector: (row: DataRow) => row.email,
      sortable: true,
      cell: (row: DataRow) => (
        <div className="flex items-center">
          {row.emailVerified ? (
            <div
              className="tooltip fixed tooltip-bottom"
              data-tip={"Email verificado"}
            >
              <i className="fas fa-check-circle mr-1 text-green-600" />
            </div>
          ) : (
            <div
              className="tooltip fixed tooltip-bottom"
              data-tip={"Email não verificado"}
            >
              <i className="fas fa-times-circle mr-1 text-red-600" />
            </div>
          )}

          <div className="ml-4">{row.email}</div>
        </div>
      ),
    },
    {
      name: "Admin.",
      width: "250px",
      right: true,
      cell: (row: DataRow) => (
        <>{row.role === "organization-admin" ? "Sim" : "Não"}</>
      ),
    },
    {
      name: "Ações",
      right: true,
      width: "250px",
      sortable: false,
      cell: (row: DataRow) => (
        <>
          <ActionList row={row} />
          {user?.adminSystem && (
            <TableActions
              row={row}
              options={[
                {
                  type: "delete",
                },
              ]}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <CustomDataTable
      columns={columns}
      data={atendentes}
      loading={loading}
      pagination
      highlightOnHover
      striped
    />
  );
}

const ActionList = ({ row }: any) => {
  const { fetchAtendentes } = useAtendentesContext();
  const [loading, setLoading] = useState(false);
  const { errorSnackbar } = useAppContext();

  return (
    <Dropdown
      label={row.disabled ? "Desativado" : "Ativo"}
      isDisabled={loading}
      buttonClass={row.disabled ? "btn-error" : "btn-success"}
      actions={[
        row.disabled
          ? {
              label: "Ativar",
              action: async () => {
                setLoading(true);

                try {
                  await service.active(row.uid);
                  fetchAtendentes();
                } catch (e) {
                  errorSnackbar("Ocorreu um erro ao alterar a situação");
                } finally {
                  setLoading(false);
                }
              },
            }
          : {
              label: "Desativar",
              action: async () => {
                setLoading(true);

                try {
                  await service.disable(row.uid);
                  fetchAtendentes();
                } catch (e) {
                  errorSnackbar("Ocorreu um erro ao alterar a situação");
                } finally {
                  setLoading(false);
                }
              },
            },
      ]}
    />
  );
};
