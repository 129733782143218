import { ActionsService } from "./Actions";
import { DurationService } from "./Duration";
import StatusService from "./Status";

export const columns = [
  {
    name: "Situação",
    width: "150px",
    cell: (row: any) => <StatusService id={row._id} status={row.status} />,
  },
  {
    name: "Nome",
    selector: (row: any) => row.title,
    sortable: true,
    cell: (row: any) => <div style={{ textAlign: "right" }}>{row.name}</div>,
  },
  {
    name: "Duração",
    selector: (row: any) => row.duracao,
    sortable: true,
    cell: (row: any) => <DurationService duration={row.duration} />,
  },
  {
    name: "Ações",
    right: true,
    sortable: false,
    width: "250px",
    cell: (row: any) => <ActionsService row={row} />,
  },
];
