import { useState } from "react";
import { FileModal } from "./FileModal";
import { urlFile } from "../../AxiosConfig";

interface FilePreviewImageProps {
  fileId: string;
}

export const FilePreviewImage = ({ fileId }: FilePreviewImageProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isImageLoaded, setImageLoaded] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div
        style={{ marginRight: "5px" }}
        className="avatar cursor-pointer" // Adicione a classe cursor-pointer aqui
        onClick={openModal}
      >
        <div className="relative w-12 rounded overflow-hidden"> {/* Adicione overflow-hidden para o efeito de escurecimento */}
          {!isImageLoaded && (
            <div className="w-full h-full object-cover">
              <span
                className="loading loading-spinner loading-sm absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              ></span>
            </div>
          )}
          <img
            src={`${urlFile}/${fileId}`}
            alt="file preview"
            className={`w-full h-full object-cover transition-opacity duration-200 ${
              isImageLoaded ? "" : "hidden"
            }`}
            onLoad={handleImageLoad}
          />
          <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-200 hover:opacity-50"></div> {/* Div para o efeito de escurecimento */}
        </div>
      </div>
      {isModalOpen && (
        <FileModal
          key={fileId}
          fileId={fileId}
          onClose={closeModal}
        />
      )}
    </>
  );
};
