import React, { ReactNode } from "react";
import { useParamsContext } from "../../contexts/ParamsContext";

interface Option {
  type: string;
  onClick?: (row: any) => void;
}

interface TableActionsProps {
  row?: any;
  dropdown?: boolean;
  options?: Option[];
  children?: ReactNode;
}

const TableActions: React.FC<TableActionsProps> = ({
  row,
  options,
  children,
  dropdown = false,
}) => {
  const { setParams } = useParamsContext();

  return (
    <>
      <ul className="menu menu-horizontal rounded-lg p-0">
        {!!children && children}

        {dropdown ? (
          <div role="button" className="dropdown dropdown-left">
            <li tabIndex={0}>
              <a>
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </a>
            </li>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
            >
              {options?.map((option, index) => (
                <li key={index}>
                  <a
                    onClick={() => {
                      setParams({ action: option.type, id: row?._id || row?.id || row?.uid });
                    }}
                  >
                    {option.type === "edit" ? (
                      "Editar"
                    ) : option.type === "delete" ? (
                      "Remover"
                    ) : option.type === "history" ? (
                      "Histórico"
                    ) : (
                      <span>{option.type}</span>
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <ul className="menu menu-horizontal rounded-lg p-0">
              {options?.map((option, index) => (
                <li key={index}>
                  <a
                    onClick={() => {
                      setParams({ action: option.type, id: row?._id || row?.id || row?.uid });
                    }}
                  >
                    {option.type === "edit" ? (
                      <i className="fa-solid fa-pen-to-square"></i>
                    ) : option.type === "delete" ? (
                      <i className="fa-solid fa-trash"></i>
                    ) : option.type === "history" ? (
                      <i className="fa-solid fa-clock-rotate-left"></i>
                    ) : (
                      <span>{option.type}</span>
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </ul>
    </>
  );
};

export default TableActions;
