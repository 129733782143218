import { useParamsContext } from "../../../contexts/ParamsContext";
import ScheduleList from "./ScheduleList";
import AgendaSettings from "./settings/Settings";

const Agenda = () => {
  const { action } = useParamsContext();

  switch (action) {
    case "settings": {
      return <AgendaSettings />;
    }
    case "list":
    default:
      return <ScheduleList />;
  }
};

export default Agenda;
