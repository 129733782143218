import ConfiguracoesContaEmail from "./forms/Email";
import ConfiguracoesContaGeral from "./forms/Geral";
import ConfiguracoesContaPassword from "./forms/Password";
import ConfiguracoesContaPhoneNumber from "./forms/PhoneNumber";

export default function ConfiguracoesConta() {
  return (
    <div className="container mx-auto p-4">
      <div role="tablist" className="tabs tabs-bordered">
        <input
          type="radio"
          name="my_tabs_1"
          role="tab"
          className="tab"
          aria-label="Geral"
          defaultChecked
        />
        <div role="tabpanel" className="tab-content px-5 pt-5">
          <ConfiguracoesContaGeral />
        </div>

        <input
          type="radio"
          name="my_tabs_1"
          role="tab"
          className="tab"
          aria-label="Email"
        />
        <div role="tabpanel" className="tab-content px-5 pt-5">
          <ConfiguracoesContaEmail />
        </div>

        <input
          type="radio"
          name="my_tabs_1"
          role="tab"
          className="tab"
          aria-label="Número de telefone"
        />
        <div role="tabpanel" className="tab-content px-5 pt-5">
          <ConfiguracoesContaPhoneNumber />
        </div>

        <input
          type="radio"
          name="my_tabs_1"
          role="tab"
          className="tab"
          aria-label="Senha"
        />
        <div role="tabpanel" className="tab-content px-5 pt-5">
          <ConfiguracoesContaPassword />
        </div>
      </div>
    </div>
  );
}
