import { useEffect, useState } from "react";
import { FileUpload } from "../../../../components/files/FilesUpload";

interface ImageEstabProps {
  imageId: string;
  setImageId: (imageId: string) => void;
}

export const ImageEstab = ({ imageId, setImageId }: ImageEstabProps) => {
  const [value, setValue] = useState<string[]>([imageId]);

  useEffect(() => {
    setImageId(value?.[0] || "");
  }, [value, setImageId]);

  return (
    <FileUpload value={value} setValue={setValue} multiple={false}>
      {({ fileData, handleChange, inputRef }) => {
        return (
          <div className="grid m-auto">
            <div className="mx-auto mb-3">
              <div className="avatar">
                <div className="w-56 h-12 rounded border border-gray-200 text-center content-center relative">
                  {fileData?.[0]?.uploading ? (
                    <div className="flex items-center justify-center">
                      <img
                        src={fileData?.[0]?.fileContent}
                        alt="file preview"
                        className="absolute w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black opacity-30"></div>{" "}
                      <span
                        className="loading loading-spinner loading-sm absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        style={{ color: "#ffffff" }}
                      ></span>
                    </div>
                  ) : fileData?.[0]?.url ? (
                    <img
                      src={fileData?.[0]?.url}
                      alt="file preview"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <input
                type="file"
                ref={inputRef}
                accept="image/*"
                onChange={(e: any) => {
                  const selectedFiles: any = Array.from(e.target.files || []);
                  handleChange(selectedFiles);
                }}
                className="file-input file-input-bordered file-input-sm ml-4"
              />
            </div>
          </div>
        );
      }}
    </FileUpload>
  );
};
