import { api } from "../AxiosConfig";

export interface ScheduleHistoryToView {
  id: string;
  message: string;
  date: Date;
  user: {
    name: string;
  };
}

export class HistoryService {
  getHistory(id: string): Promise<ScheduleHistoryToView[]> {
    return api.get(`/historys/${id}`);
  }
}
