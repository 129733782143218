import moment from "moment";
import { useParamsContext } from "../../../contexts/ParamsContext";
import { Schedule } from "./ContextAgenda";
import ScheduleStatus from "./components/ScheduleStatus";
moment.locale("pt-br");

interface ScheduleItemProps {
  schedule: Schedule;
}

export default function ScheduleItem({ schedule }: ScheduleItemProps) {
  const { setParams } = useParamsContext();

  const currentTime = moment();
  const start = moment(schedule.start);
  const end = moment(schedule.end);

  const isCurrentTime = currentTime.isBetween(start, end);

  return (
    <div>
      <div
        className="flex items-center p-4 mb-2 rounded-lg shadow-md bg-white hover:bg-sky-50 cursor-pointer"
        onClick={(e: any) => {
          if (!e.target?.className?.includes("actionStatus")) {
            setParams({
              action: "view",
              id: schedule.id,
            });
          }
        }}
      >
        <div className="flex-grow">
          <div
            className={`font-semibold flex justify-between items-center mb-2 ${
              isCurrentTime && "text-red-500 font-bold"
            }`}
          >
            {schedule.client.name}
            <div>
              <ScheduleStatus status={schedule.status} id={schedule.id} />
            </div>
          </div>
          <div className="text-gray-600">
            {start.format("HH:mm")} - {end.format("HH:mm")} |{" "}
            {schedule.service.name}
          </div>

          <div className="text-xs text-gray-600">{schedule.attendant.name}</div>
        </div>
      </div>
    </div>
  );
}
