import { useNavigate } from "react-router-dom";
import { timeAgo } from "../../common";
import { useNotificationsContext } from "./NotificationsContext";
import { NotificationsService } from "./Service";
const service = new NotificationsService();

export default function NotificationItem({ notification }: any) {
  const navigate = useNavigate();
  const { setDropdownOpen, loadNotifications } = useNotificationsContext();

  const onClick = () => {
    if (!!notification.path) {
      navigate(notification.path);
      setDropdownOpen(false);
    }
  };

  return (
    <li
      key={notification.id}
      className="mb-3 w-full group/item"
      onClick={() => {
        service.view(notification.id).then(() => {
          loadNotifications();
        });
      }}
    >
      <div
        className="flex items-center justify-between w-full"
        style={{ padding: "3px 3px", display: "block" }}
      >
        <div
          className="flex items-center justify-between w-full"
          onClick={onClick}
        >
          <div
            className={`text-sm mb-1 ${
              !notification.visualizedAt && "font-extrabold"
            }`}
          >
            {notification.title}
          </div>
          <div
            className={`text-xs ${!!notification.visualizedAt && "font-light"}`}
          >
            {timeAgo(notification.createdAt)}
          </div>
        </div>

        <div className="flex justify-between">
          <div
            className={`text-xs ${
              !notification.visualizedAt ? "font-medium" : "font-light"
            }`}
            onClick={onClick}
          >
            {notification.message}
          </div>
        </div>
      </div>
    </li>
  );
}
