import { api } from "../../AxiosConfig";

class ServicosService {
  create(data: any) {
    return api.post("/servicos/create", data);
  }

  findAll() {
    return api.get("/servicos/findAll");
  }

  update(id: string, update: any) {
    return api.post("/servicos/update", { id, update });
  }

  delete(id: string) {
    return api.post("/servicos/delete", { id });
  }
}

export { ServicosService };
