import { useEstabsContext } from "../../../contexts/EstabsContext";
import { EstabsClientAddFirst } from "../../estabelecimentos/client/AddFirst";

export default function AgendaCliente() {
  const { userClientEstabs } = useEstabsContext();

  return userClientEstabs.length > 0 ? <Agenda /> : <EstabsClientAddFirst />;
}

const Agenda = () => {
  return (
    <div>
      <div className="text-center text-4xl  text-gray-700 mb-6">
        Nenhum agendamento
      </div>

      <div className="flex flex-col items-center">
        <button className="btn btn-secondary btn-lg btn-circle bottom-6 shadow-2xl hover:bg-blue-600 transform hover:scale-105 transition-all duration-300">
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
  );
};
