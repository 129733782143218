import moment from "moment";
import "moment/locale/pt-br";
import { useAgendaContext } from "./ContextAgenda";
import ScheduleItem from "./ScheduleItem";
import ScheduleView from "./view/ScheduleView";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AvatarUser from "../../../components/AvatarUser";

moment.locale("pt-br");

export default function ScheduleList() {
  const {
    date,
    handlePreviousDay,
    handleNextDay,
    schedules,
    selectedSchedule,
    loading,
    setDate,
    atendentes,
    setFilter,
    filter,
  } = useAgendaContext();
  const startTime = moment().startOf("day").hour(8);
  const endTime = moment().startOf("day").hour(20);

  const startOfWeek = moment(date).startOf("week");
  const endOfWeek = moment(date).endOf("week");
  const days = [];

  for (let day = startOfWeek; day <= endOfWeek; day.add(1, "days")) {
    days.push(day.clone());
  }

  const renderTimeSlots = () => {
    const timeSlots = [];

    while (startTime <= endTime) {
      const slotTasks = schedules
        ? schedules.filter((task: any) => {
            const taskStart = moment(task.start);
            return (
              taskStart.isSameOrAfter(startTime) &&
              taskStart.isBefore(moment(startTime).add(30, "minutes"))
            );
          })
        : [];

      if (slotTasks.length > 0) {
        timeSlots.push(
          <div key={startTime.format("HH:mm")}>
            <div className={`h-12 flex items-center`}>
              {startTime.format("HH:mm")}
            </div>
            <div>
              {slotTasks.map((schedule: any, i: any) => {
                return <ScheduleItem key={i} schedule={schedule} />;
              })}
            </div>
          </div>
        );
      }

      startTime.add(30, "minutes");
    }
    return timeSlots;
  };

  return (
    <div className="container mx-auto my-8">
      <div className="flex justify-between">
        <DatePicker
          selected={moment(date).toDate()}
          onChange={setDate}
          locale="pt-BR"
          customInput={
            <button className="btn btn-ghost btn-sm">
              <div className="text-xl font-bold">
                {moment(date).format("DD [de] MMMM [de] YYYY")}
              </div>
            </button>
          }
        />
      </div>

      <div className="flex items-center justify-between my-2">
        <div className="flex items-center">
          <button
            className="btn btn-square"
            onClick={() => setDate(new Date())}
          >
            Hoje
          </button>

          <button className="btn btn-circle ml-2" onClick={handlePreviousDay}>
            <i className="fa-solid fa-angle-left"></i>
          </button>

          {days.map((day, index) => {
            const isSelected = day.isSame(date, "day");

            return (
              <button
                key={index}
                className={`btn btn-circle ml-1 mr-1 btn-s ${
                  isSelected && "btn-info"
                }`}
                style={{ display: "grid" }}
                onClick={() => {
                  setDate(day);
                }}
              >
                <div className="h-0">{day.format("ddd")}</div>
                <div className="font-light">{day.format("DD")}</div>
              </button>
            );
          })}

          <button className="btn btn-circle ml-2 mr-6" onClick={handleNextDay}>
            <i className="fa-solid fa-angle-right"></i>
          </button>
        </div>
        <button className="btn">Agendar</button>
      </div>

      <div className="flex gap-1">
        {atendentes.length &&
          atendentes.map((atendente: any, index: any) => {
            return (
              <button
                key={index}
                className={`btn btn-circle ${
                  filter?.atendentesIds?.includes(atendente.uid)
                    ? "btn-info"
                    : "btn-ghost"
                }`}
                style={{ display: "grid" }}
                onClick={() => {
                  const doc = {
                    ...filter,
                    atendentesIds: filter?.atendentesIds?.includes(
                      atendente.uid
                    )
                      ? filter.atendentesIds.filter(
                          (id: any) => id !== atendente.uid
                        )
                      : [...(filter.atendentesIds || []), atendente.uid],
                  };

                  setFilter(doc);
                }}
              >
                <AvatarUser
                  photoURL={atendente.photoURL}
                  name={atendente.displayName || atendente.email}
                />
              </button>
            );
          })}
      </div>

      <div className="divider"></div>

      {loading ? (
        <div className="w-full h-full items-center justify-center flex">
          <div className="text-center">
            <span className="loading loading-bars loading-lg"></span>
          </div>
        </div>
      ) : (
        <div className="flex gap-4">
          <div className={!!selectedSchedule ? "w-4/12" : "w-full"}>
            {renderTimeSlots()}
          </div>
          {!!selectedSchedule && (
            <div className="w-8/12">
              <ScheduleView />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
