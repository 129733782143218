import React, { useState } from "react";

interface DropdownAction {
  label: string;
  action: () => Promise<void>;
}

interface DynamicDropdownProps {
  label: string;
  isDisabled: boolean;
  actions: DropdownAction[];
  buttonClass: string;
}

const Dropdown: React.FC<DynamicDropdownProps> = ({
  label,
  isDisabled,
  actions,
  buttonClass,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(true);

  const handleActionClick = async (action: () => Promise<void>) => {
    setDropdownOpen(false);
    await action();
  };

  return (
    <div className="dropdown">
      <button
        className={`btn btn-outline ${buttonClass} btn-sm`}
        disabled={isDisabled}
        onClick={() => setDropdownOpen(true)}
      >
        {label}
      </button>
      <ul
        style={{ visibility: dropdownOpen ? "inherit" : "hidden" }}
        className={`dropdown-content z-[1] menu p-2 bg-base-100 rounded-box shadow-lg invisible`}
      >
        {actions.map((action, index) => (
          <li key={index} onClick={() => handleActionClick(action.action)}>
            <button className="w-full text-left">{action.label}</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
