import { api } from "../../AxiosConfig";

class UsuariosSistemaService {
  create(data: any) {
    return api.post("/users/system-admin/create", data);
  }

  getAll() {
    return api.get("/users/system-admin/getAll");
  }

  disable(uid: string) {
    return api.post("/users/system-admin/disable", { uid });
  }

  active(uid: string) {
    return api.post("/users/system-admin/active", { uid });
  }

  delete(uid: string) {
    return api.post("/users/system-admin/delete", { uid });
  }
}

export { UsuariosSistemaService };
