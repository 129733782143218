import { useParamsContext } from "../../../contexts/ParamsContext";

interface Actions {
  type: string;
}

interface ContentActionProps {
  actions: Actions[];
}

export default function ContentActions(options: ContentActionProps) {
  const { setParams, action: actionParam } = useParamsContext();

  const parserAction = (action: any) => {
    switch (action.type) {
      case "add":
        return (
          !actionParam && (
            <li
              onClick={() => {
                setParams({ action: "add" });
              }}
            >
              <a>Adicionar</a>
            </li>
          )
        );
      case "settings":
        return (
          !actionParam && (
            <li
              onClick={() => {
                setParams({ action: "settings" });
              }}
            >
              <a>Configurações</a>
            </li>
          )
        );
      default:
        return <></>;
    }
  };

  return (
    <ul className="menu lg:menu-horizontal rounded-lg">
      {options.actions.map((action, i) => <div key={i}>{parserAction(action)}</div>)}
    </ul>
  );
}
