export default function PageLoading() {
  return (
    <div className="w-full h-full items-center justify-center flex">
      <div className="text-center">
        <img src="logo.png" className="h-16 mb-4" />
        <span className="loading loading-bars loading-lg"></span>
      </div>
    </div>
  );
}
