import moment from "moment";

export function timeAgo(date: Date): string {
    const now = moment();
    const inputDate = moment(date);
  
    if (now.isSame(inputDate, "day")) {
      return `hoje às ${inputDate.format("HH:mm")}`;
    }
  
    const diffInSeconds = now.diff(inputDate, "seconds");
  
    if (diffInSeconds < 60) {
      return `há ${diffInSeconds} segundos`;
    }
  
    const diffInMinutes = now.diff(inputDate, "minutes");
    if (diffInMinutes < 60) {
      return `há ${diffInMinutes} minuto(s)`;
    }
  
    const diffInHours = now.diff(inputDate, "hours");
    if (diffInHours < 24) {
      return `há ${diffInHours} hora(s)`;
    }
  
    const diffInDays = now.diff(inputDate, "days");
    if (diffInDays < 7) {
      return `há ${diffInDays} dia(s)`;
    }
  
    const diffInWeeks = now.diff(inputDate, "weeks");
    if (diffInWeeks < 4) {
      return `há ${diffInWeeks} semana(s)`;
    }
  
    const diffInMonths = now.diff(inputDate, "months");
    if (diffInMonths < 12) {
      return `há ${diffInMonths} mese(s)`;
    }
  
    const diffInYears = now.diff(inputDate, "years");
    return `há ${diffInYears} ano(s)`;
  }