import { useEffect, useState } from "react";
import CustomDataTable from "../../components/table/Table";
import AgendaSettingsAusenciasForm from "./AusenciasForm";
import AusenciasDelete from "./Delete";
import { useAusenciasContext } from "./Context";
import { useAtendentesContext } from "../atendentes/Context";

export default function AgendaSettingsAusencias() {
  const [remove, setRemove] = useState<any>();
  const [showForm, setShowForm] = useState<any>();
  const { ausencias, loading } = useAusenciasContext();

  const columns = [
    {
      name: "Início",
      selector: (row: any) =>
        row.start ? new Date(row.start).toLocaleString() : "",
      sortable: true,
    },
    {
      name: "Fim",
      selector: (row: any) =>
        row.end ? new Date(row.end).toLocaleString() : "",
      sortable: true,
    },
    {
      name: "Motivo",
      selector: (row: any) => row.motivo,
      sortable: true,
    },
    {
      name: "Atendentes",
      selector: (row: any) => (
        <CellAtendentes atendentesIds={row?.atendentesIds} />
      ),
      sortable: true,
    },
    {
      name: "Ações",
      right: true,
      sortable: false,
      width: "250px",
      cell: (row: any) => (
        <div className="flex gap-2">
          <button
            type="button"
            className="btn btn-xs"
            onClick={() => setShowForm({ id: row.id })}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </button>

          <button
            type="button"
            className="btn btn-xs btn-error"
            onClick={() => {
              setRemove({ id: row.id });
            }}
          >
            <i className="fa-solid fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  if (!!showForm) {
    return (
      <AgendaSettingsAusenciasForm
        showForm={showForm}
        setShowForm={setShowForm}
      />
    );
  }

  if (!!remove) {
    return <AusenciasDelete remove={remove} setRemove={setRemove} />;
  }

  return (
    <div className="">
      <button
        type="button"
        className="btn btn-sm"
        onClick={() => setShowForm({})}
      >
        Adicionar
      </button>

      <CustomDataTable
        columns={columns}
        data={ausencias}
        pagination
        loading={loading}
        highlightOnHover
        striped
      />
    </div>
  );
}

const CellAtendentes = ({ atendentesIds }: any) => {
  const { atendentes } = useAtendentesContext();
  const [atendentesName, setAtendentesName] = useState<string>("");

  useEffect(() => {
    if (!!atendentes?.length) {
      setAtendentesName(
        atendentes
          ?.filter((e) => atendentesIds?.includes(e.uid))
          ?.map((atendente: any) => atendente.displayName)
          ?.join(", ")
      );
    }
  }, [atendentes, atendentesIds]);

  return <>{atendentesName}</>;
};
