import { ScheduleStatusEnum } from "../ContextAgenda";
import Dropdown from "./Dropdown";

interface ScheduleStatusProps {
  id: string;
  status: ScheduleStatusEnum;
}

export default function ScheduleStatus({ status, id }: ScheduleStatusProps) {
  switch (status) {
    case ScheduleStatusEnum.Pending:
      return (
        <Dropdown
          options={[
            {
              label: "Confirmar",
              onClick: () => null,
            },
            {
              label: "Remarcar",
              onClick: () => null,
            },
            {
              label: "Cancelar",
              onClick: () => null,
            },
          ]}
        >
          <button
            className={`btn btn-sm btn-warning opacity-80 w-full actionStatus`}
          >
            Pendente
          </button>
        </Dropdown>
      );
    case ScheduleStatusEnum.Confirmed:
      return (
        <Dropdown
          options={[
            {
              label: "Concluir",
              onClick: () => null,
            },
            {
              label: "Remarcar",
              onClick: () => null,
            },
            {
              label: "Cancelar",
              onClick: () => null,
            },
          ]}
        >
          <button
            className={`btn btn-sm btn-primary opacity-80 w-full actionStatus`}
          >
            Confirmado
          </button>
        </Dropdown>
      );
    case ScheduleStatusEnum.Completed:
      return (
        <button
          type="button"
          className={`btn btn-sm btn-success opacity-80 cursor-auto actionStatus`}
        >
          Concluído
        </button>
      );
    case ScheduleStatusEnum.Canceled:
      return (
        <button
          type="button"
          className={`btn btn-sm btn-error opacity-80 cursor-auto actionStatus`}
        >
          Cancelado
        </button>
      );
    default:
      return (
        <button type="button" className={`btn opacity-80 btn-sm actionStatus`}>
          btn-secondary
        </button>
      );
  }
}
