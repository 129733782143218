import { Routes } from "react-router-dom";
import Header from "./header/Header";
import Menu from "./menu/Menu";
import { routes } from "../../routes";
import { useAppContext } from "../../contexts/AppContext";
import { ContentProvider } from "./content/ContentContext";
import { useAuthContext } from "../../contexts/AuthContext";

export default function DashboardPage() {
  const { user } = useAuthContext();
  const { shortMenu } = useAppContext();
  
  return (
    <div className="mx-auto w-12/12 2xl:w-8/12">
      <div className="mb-2 pb-3 fixed z-50 w-full 2xl:w-8/12">
        <Header />
      </div>

      <div className="flex">
        <div className="fixed mt-20 z-40">
          <Menu />
        </div>

        <div className={`pt-1 w-full mt-20 mr-5 ${shortMenu ? "ml-32" : "ml-52"}`}>
          <ContentProvider>
            <Routes>{routes(user)}</Routes>
          </ContentProvider>
        </div>
      </div>
    </div>
  );
}
