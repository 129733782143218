import CustomDataTable from "../../../components/table/Table";
import { useServicosContext } from "../Context";
import { columns } from "./Columns";

export default function ServicosList() {
  const { data, loading } = useServicosContext();

  return (
    <CustomDataTable
      columns={columns}
      data={data}
      pagination
      loading={loading}
      highlightOnHover
      striped
    />
  );
}
