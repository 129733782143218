import { ComponentType } from "react";
import { useAppContext } from "../../../contexts/AppContext";
import HeaderContent from "./Header";

interface ContentProps {
  title: string;
  Element: ComponentType;
  Actions: ComponentType | any;
  returnHome?: boolean;
}

export default function Content({
  title,
  Element,
  Actions,
  returnHome,
}: ContentProps) {
  const { isScrolled } = useAppContext();

  return (
    <div>
      <div
        className={`mb-2 pb-3 border-b border-gray-100 z-50 transition-all duration-400 ease-in-out ${
          isScrolled
            ? "opacity-0 transform -translate-y-10"
            : "opacity-100 transform translate-y-0"
        }`}
      >
        <HeaderContent
          title={title}
          Actions={Actions}
          returnHome={returnHome}
        />
      </div>

      <Element />
    </div>
  );
}
